import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, numberWithCommas, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useTheme } from '../ThemeProvider.js'
import { useContent } from '../ContentProvider.js'
import Button from '../ui/Button.js'
import { openChat } from '../Components/ChatButton.js'
import { currencySymbols } from '../ui/formatters.js'
import HomeGraph from './HomeGraph.js'
import RevenueGraph from './RevenueGraph.js'

const FloatingBlueBox = styled(Box)`
  width: 108px;
  height: 121px;
  transform: rotate(19.921deg);
  position: absolute;
  right: -43.765px;
  top: -48px;
  z-index: 1;
  background-color: rgba(91, 179, 249, 1);
  border-radius: 8px;
`

const InnerContainer = styled(Flex)`
  border-radius: 16px;
`

const ImageContainer = styled(Flex)`
  background-color: rgba(192, 192, 192, 0.8);
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  flex-direction: column-reverse;
  padding: 16px;
  position: relative;
  overflow: hidden;
`

const Grid = styled(Flex)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

const Home = (props) => {
  let { user, setUser } = useAuth()

  const { theme } = useTheme()
  const { recipes } = useContent()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const [hasStripeAccount, setHasStripeAccount] = useState(false)
  const [hasRequirements, setHasRequirements] = useState(false)
  const [copiedHomepage, setCopiedHomepage] = useState(false)
  const [copiedSignup, setCopiedSignup] = useState(false)
  const [graphData, setGraphData] = useState({
    subscribersByDay: [],
    allCustomers: [],
    newPaidSubscribers: [],
    totalRevenue: []
  })

  const currencySymbol = currencySymbols[theme.plans?.[0]?.currency] || ''

  const refresh = async () => {
    const [homepageRes, graphRes] = await Promise.all([api.get('/dashboard/homepage'), api.get('/dashboard/homepage-graph')])

    if (homepageRes.data.success) {
      setData(homepageRes.data.payload)
    }

    if (graphRes.data.success) {
      setGraphData(graphRes.data.payload)
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  const dismissLaunchSteps = async () => {
    const res = await api.post('/auth/user/update-tags', { tags: { dismissLaunchSteps: true } })
    if (res.data.success) {
      const res = await api.get('/auth')
      if (res.data.success) {
        setUser(res.data.payload)
      }
    }
  }

  useEffect(() => {
    const asyncFunction = async () => {
      const res = await api.get('stripe/check-connect-account-requirements')
      setHasRequirements(res.data.payload.hasRequirements)
      setHasStripeAccount(res.data.payload.hasStripeAccount)
    }
    asyncFunction()
  }, [])

  const handleCopy = async (text, setCopied) => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <Flex maxWidth="calc(100vw - 250px)">
      <Flex position="relative" flexDirection="column" width="100%" p="40px">
        <Flex flexDirection="column" mb="32px">
          <H1 mb="8px" width="auto">
            Hey {user.firstName}
          </H1>
          <Text fontSize="16px" light>
            Here’s what you’ve been up to
          </Text>
        </Flex>
        <Flex>
          <Flex flexDirection="column" maxWidth="700px">
            {user?.tags?.dismissLaunchSteps ? null : (
              <Container p={0} overflow="hidden" mb="32px">
                <Flex position="relative" backgroundColor="#FDF4E8" height="100px" py="24px" px="45px" flexDirection="column">
                  <FloatingBlueBox />
                  <H3 mb="8px">Prepare for launch</H3>
                  <Text fontSize="14px" light>
                    Tick off the steps to success
                  </Text>
                </Flex>
                <Flex flexDirection="column" p="20px">
                  <InnerContainer px="24px" py="16px" mb="16px" boxShadow={'none'}>
                    <Flex alignItems="center">
                      <Icon mr="24px" icon="tickbox-green" width="20px" height="20px" />
                      <Text light>Create your Clubb</Text>
                    </Flex>
                  </InnerContainer>
                  <InnerContainer
                    px="24px"
                    py="16px"
                    mb="16px"
                    boxShadow={
                      theme && theme.logo !== 'https://clipara.b-cdn.net/clubb-images/38446001-18cb-45be-8985-d300b663a4bc.png'
                        ? 'none'
                        : '0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);'
                    }
                  >
                    <Flex alignItems="center">
                      <Icon
                        mr="24px"
                        icon={
                          theme &&
                          theme.logo !== 'https://clipara.b-cdn.net/clubb-images/38446001-18cb-45be-8985-d300b663a4bc.png'
                            ? 'tickbox-green'
                            : 'tickbox-empty'
                        }
                        width="20px"
                        height="20px"
                      />
                      <Text light>Change your theme</Text>
                    </Flex>
                  </InnerContainer>
                  <InnerContainer
                    px="24px"
                    py="16px"
                    mb="16px"
                    boxShadow={
                      user && user.stripeConnectAccountId
                        ? 'none'
                        : '0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);'
                    }
                  >
                    <Flex alignItems="center">
                      <Icon
                        mr="24px"
                        icon={hasStripeAccount && !hasRequirements ? 'tickbox-green' : 'tickbox-empty'}
                        width="20px"
                        height="20px"
                      />
                      <Text light>Connect your Stripe account</Text>
                    </Flex>
                  </InnerContainer>
                  <InnerContainer
                    px="24px"
                    py="16px"
                    mb="16px"
                    boxShadow={
                      recipes && recipes.length
                        ? 'none'
                        : '0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);'
                    }
                  >
                    <Flex alignItems="center">
                      <Icon
                        mr="24px"
                        icon={recipes && recipes.length ? 'tickbox-green' : 'tickbox-empty'}
                        width="20px"
                        height="20px"
                      />
                      <Text light>Add your first piece of content</Text>
                    </Flex>
                  </InnerContainer>
                  <InnerContainer
                    px="24px"
                    py="16px"
                    boxShadow={
                      data && data.hasFirstSubscriber
                        ? 'none'
                        : '0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);'
                    }
                  >
                    <Flex alignItems="center">
                      <Icon
                        mr="24px"
                        icon={data && data.hasFirstSubscriber ? 'tickbox-green' : 'tickbox-empty'}
                        width="20px"
                        height="20px"
                      />
                      <Text light>Get your first subscriber</Text>
                    </Flex>
                  </InnerContainer>
                  {theme &&
                  theme.logo !== 'https://clipara.b-cdn.net/clubb-images/38446001-18cb-45be-8985-d300b663a4bc.png' &&
                  recipes &&
                  recipes.length &&
                  user &&
                  user.stripeConnectAccountId &&
                  data &&
                  data.hasFirstSubscriber ? (
                    <Flex justifyContent="flex-end" mt="12px">
                      <Button label="Dismiss" variant="green" onClick={dismissLaunchSteps} />
                    </Flex>
                  ) : null}
                </Flex>
              </Container>
            )}
            <Flex mb="32px">
              <Container p="24px" py="8px" mr="24px">
                <Text fontWeight="600" light mb="8px" lineHeight="24px">
                  All Subscribers
                </Text>
                <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                  {typeof data?.totalCustomers !== 'undefined' ? numberWithCommas(data?.totalCustomers) : ''}
                </Text>
                <Text
                  light
                  fontWeight="500"
                  lineHeight="24px"
                  color={data?.totalCustomers - data?.totalCustomers30Days > 0 ? colors.green : undefined}
                >
                  {typeof data?.totalCustomers !== 'undefined'
                    ? `${data.totalCustomers - data.totalCustomers30Days > 0 ? '+' : ''}${numberWithCommas(
                        data.totalCustomers - data.totalCustomers30Days
                      )} vs previous 30 days`
                    : null}
                </Text>
              </Container>
              <Container p="24px" py="8px">
                <Text fontWeight="600" light mb="8px" lineHeight="24px">
                  Paid Subscribers
                </Text>
                <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                  {typeof data?.totalSubscribersNew !== 'undefined' ? numberWithCommas(data?.totalSubscribersNew) : ''}
                </Text>
                <Text
                  light
                  fontWeight="500"
                  lineHeight="24px"
                  color={data?.totalSubscribersNew - data?.totalSubscribersNew30Days > 0 ? colors.green : undefined}
                >
                  {typeof data?.totalSubscribersNew !== 'undefined'
                    ? `${data.totalSubscribersNew - data.totalSubscribersNew30Days > 0 ? '+' : ''}${numberWithCommas(
                        data.totalSubscribersNew - data.totalSubscribersNew30Days
                      )} vs previous 30 days`
                    : null}
                </Text>
              </Container>
            </Flex>
            <Flex mb="32px">
              <Container p="24px" py="8px" mr="24px">
                <Text fontWeight="600" light mb="8px" lineHeight="24px">
                  Monthly Recurring Revenue
                </Text>
                <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                  {currencySymbol}
                  {typeof data?.mrr !== 'undefined' ? numberWithCommas(Math.round(data?.mrr)) : ''}
                </Text>
                <Text light fontWeight="500" lineHeight="24px" color={data?.mrr - data?.mrr30Days > 0 ? colors.green : undefined}>
                  {typeof data?.mrr !== 'undefined'
                    ? `${data.mrr - data.mrr30Days > 0 ? '+' : ''}${currencySymbol}${numberWithCommas(
                        Math.round(data.mrr - data.mrr30Days)
                      )} vs previous 30 days`
                    : null}
                </Text>
              </Container>
              <Container p="24px" py="8px">
                <Text fontWeight="600" light mb="8px" lineHeight="24px">
                  Annualised Recurring Revenue
                </Text>
                <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                  {currencySymbol}
                  {typeof data?.arr !== 'undefined' ? numberWithCommas(Math.round(data?.arr)) : ''}
                </Text>
                <Text light fontWeight="500" lineHeight="24px" color={data?.arr - data?.arr30Days > 0 ? colors.green : undefined}>
                  {typeof data?.arr !== 'undefined'
                    ? `${data.arr - data.arr30Days > 0 ? '+' : ''}${currencySymbol}${numberWithCommas(
                        Math.round(data.arr - data.arr30Days)
                      )} vs previous 30 days`
                    : null}
                </Text>
              </Container>
            </Flex>
            <Flex mb="32px">
              <Container p="24px" py="8px" mr="24px">
                <Text fontWeight="600" light mb="8px" lineHeight="24px">
                  Revenue Last 30 Days
                </Text>
                <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                  {currencySymbol}
                  {typeof data?.totalRevenue !== 'undefined' ? numberWithCommas(Math.round(data?.totalRevenue)) : ''}
                </Text>
                <Text
                  light
                  fontWeight="500"
                  lineHeight="24px"
                  color={data?.totalRevenue - data?.totalRevenue30Days > 0 ? colors.green : undefined}
                >
                  {typeof data?.totalRevenue !== 'undefined'
                    ? `${data.totalRevenue - data.totalRevenue30Days > 0 ? '+' : ''}${currencySymbol}${numberWithCommas(
                        Math.round(data.totalRevenue - data.totalRevenue30Days)
                      )} vs previous 30 days`
                    : null}
                </Text>
              </Container>
              <Container p="24px" py="8px">
                <Text fontWeight="600" light mb="8px" lineHeight="24px">
                  Free Trials
                </Text>
                <Text fontSize="24px" fontWeight="500" mb="8px" lineHeight="normal">
                  {typeof data?.freeTrials !== 'undefined' ? numberWithCommas(Math.round(data?.freeTrials)) : ''}
                </Text>
                <Text light fontWeight="500" lineHeight="24px"></Text>
              </Container>
            </Flex>
            <HomeGraph
              hasFirstSubscriber={data.hasFirstSubscriber}
              subscribersByDay={graphData.subscribersByDay}
              allCustomers={graphData.allCustomers}
            />
            <RevenueGraph totalRevenue={graphData.totalRevenue} />
            <Container p="24px">
              <H3 mb="24px">Latest Content</H3>
              {recipes && recipes.length ? (
                <>
                  <Grid>
                    {recipes.slice(0, 3).map((recipe, i) => {
                      const image = recipe.coverImageUrl
                        ? `${recipe.coverImageUrl}?width=500`
                        : recipe.playbackId
                        ? `https://image.mux.com/${recipe.playbackId}/thumbnail.jpg?time=0&width=500`
                        : undefined
                      return (
                        <Link key={recipe.id} to={`/recipe/${recipe.id}`}>
                          <ImageContainer background={`url("${image}")`}>
                            <Box zIndex="2">
                              <LineClamp
                                wordBreak="break-word"
                                position="relative"
                                lines={2}
                                mb="4px"
                                fontWeight={600}
                                lineHeight="18px"
                                color="white"
                              >
                                {recipe.name}
                              </LineClamp>
                            </Box>

                            <Box
                              background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%);"
                              position="absolute"
                              bottom="0px"
                              left="0px"
                              height="180px"
                              zIndex="1"
                              width="100%"
                            />
                          </ImageContainer>
                        </Link>
                      )
                    })}
                  </Grid>
                  <Flex mt="32px" mb="8px" justifyContent="flex-end">
                    <Link to="/content">
                      <Button label="All content" variant="secondary" />
                    </Link>
                  </Flex>
                </>
              ) : (
                <Flex flexDirection="column" alignItems="center" justifyContent="center" py="100px">
                  <Text fontWeight="500" light mb="16px">
                    No content yet
                  </Text>
                  <Link to="/content">
                    <Button label="Get started" variant="green" />
                  </Link>
                </Flex>
              )}
            </Container>
          </Flex>
          <Flex ml="40px" maxWidth="420px">
            <Flex flexDirection="column">
              <Container p="24px" mb="32px">
                <Flex alignItems="center" flexDirection="column">
                  <Flex flexDirection="column">
                    <H3 mb="16px">Your website</H3>
                    <Text light mb="20px">
                      Share it everywhere!
                    </Text>
                  </Flex>
                  <Flex alignItems="flex-end" mb="20px">
                    <Input mr="8px" label="Homepage" value={`${user.subdomain}.theclubb.co`} />
                    <Button
                      variant="softBlue"
                      label={copiedHomepage ? 'Copied' : 'Copy'}
                      borderRadius="8px"
                      height="37px"
                      renderLeftIcon={() => <Icon icon="copy-blue-grey" width="12px" height="12px" />}
                      onClick={() => handleCopy(`${user.subdomain}.theclubb.co`, setCopiedHomepage)}
                    />
                  </Flex>

                  <Flex alignItems="flex-end">
                    <Input mr="8px" label="Signup Page" value={`${user.subdomain}.theclubb.co/signup`} />
                    <Button
                      variant="softBlue"
                      label={copiedSignup ? 'Copied' : 'Copy'}
                      height="37px"
                      borderRadius="8px"
                      renderLeftIcon={() => <Icon icon="copy-blue-grey" width="12px" height="12px" />}
                      onClick={() => handleCopy(`${user.subdomain}.theclubb.co/signup`, setCopiedSignup)}
                    />
                  </Flex>
                </Flex>
              </Container>
              <Container p="24px">
                <Flex alignItems="center">
                  <Flex flexDirection="column">
                    <H3 mb="16px">Need help?</H3>
                    <Text light>Chat to our support</Text>
                  </Flex>
                  <Button minWidth="120px" width="120px" variant="secondary" onClick={() => openChat()} label="Start chat" />
                </Flex>
              </Container>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Home
