import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'

const ImageContainer = styled(Flex)`
  border-radius: 8px;
  background: #fdf4e8;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const ProcessingPreview = ({ small, ...rest }) => {
  if (small) {
    return (
      <ImageContainer {...rest}>
        <Icon width="30px" height="30px" icon="optimising-gradient" />
      </ImageContainer>
    )
  }

  return (
    <ImageContainer {...rest}>
      <Icon width="46px" height="46px" mb="24px" icon="optimising-gradient" />
      <Text fontWeight="500" fontSize="18px">
        Optimising video
      </Text>
    </ImageContainer>
  )
}

export default ProcessingPreview
