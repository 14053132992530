import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { LineClamp } from './Typography'
import Icon from './Icon'
import { Box, Flex } from './Layout'

const Image = styled.img`
  box-sizing: border-box !important;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  background: -webkit-linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  animation: loading-gradient 4s linear infinite;
  background-size: 200%;
  ${(props) => {
    if (props.cardStyle === 'EXTRA_ROUNDED') return `border-radius: 16px;`
    if (props.cardStyle === 'ROUNDED') return `border-radius: 8px;`
  }}
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s ease-in-out;
`

const HoverBox = styled(Box)`
  &&:hover {
    img {
      transform: scale(1.05);
    }
  }
`

const Container = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: calc(10 / 7 * 100%);
  overflow: hidden;
  ${(props) => {
    if (props.cardStyle === 'EXTRA_ROUNDED') return `border-radius: 16px;`
    if (props.cardStyle === 'ROUNDED') return `border-radius: 8px;`
  }}
`

const MealPlanSearchCard = ({ result, showTypeIcon, config }) => {
  console.log({ result })
  let src = ''
  if (result.coverImageUrl) {
    src = result.coverImageUrl + '?width=800'
  } else if (result.recipeCoverImageUrl) {
    src = result.recipeCoverImageUrl + '?width=800'
  } else if (result.playbackId) {
    src = result.playbackId ? `https://image.mux.com/${result.playbackId}/thumbnail.jpg?time=0&width=400` : undefined
  } else if (result.mealPlanRecipes && result.mealPlanRecipes[0]?.coverImageUrl) {
    src = result.mealPlanRecipes[0].coverImageUrl + '?width=800'
  }

  return (
    <HoverBox position="relative">
      <Flex flexDirection="column" alignItems="flex-start">
        <Container key={result.id} mb="8px" cardStyle={config.cardStyle}>
          <Image draggable={false} loading="lazy" src={src} alt={result.name} bg="#dcdedc" cardStyle={config.cardStyle} />
          {showTypeIcon ? (
            <Box position="absolute" bottom="12px" right="12px">
              <Icon icon="meal-plan-white" width={24} height={24} />
            </Box>
          ) : null}
        </Container>
        <LineClamp lines={2} fontSize="14px" mb="8px" lineHeight="130%" fontWeight="500">
          {result.name}
        </LineClamp>
      </Flex>
    </HoverBox>
  )
}

export default MealPlanSearchCard
