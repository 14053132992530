import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Icon from './Icon'
import { LineClamp, Text } from './Typography'
import { minutesFormat } from './formatters'
import { Box, Flex } from './Layout'
import { ClockSvg } from '../Appearance/LatestRecipe'

const Image = styled.img`
  box-sizing: border-box !important;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  background: -webkit-linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  animation: loading-gradient 4s linear infinite;
  background-size: 200%;
  ${(props) => {
    if (props.cardStyle === 'EXTRA_ROUNDED') return `border-radius: 16px;`
    if (props.cardStyle === 'ROUNDED') return `border-radius: 8px;`
  }}
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s ease-in-out;
`

const HoverBox = styled(Box)`
  @media (min-width: 1000px) {
    &&:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
`

const Container = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: calc(10 / 7 * 100%);
  overflow: hidden;
  ${(props) => {
    if (props.cardStyle === 'EXTRA_ROUNDED') return `border-radius: 16px;`
    if (props.cardStyle === 'ROUNDED') return `border-radius: 8px;`
  }}
`

const SearchCard = ({ result, showTypeIcon, config }) => {
  const src = result.coverImageUrl
    ? `${result?.coverImageUrl}?width=600`
    : result?.playbackId
    ? `https://image.mux.com/${result?.playbackId}/thumbnail.jpg?time=0&width=450`
    : undefined

  return (
    <HoverBox position="relative">
      <Flex flexDirection="column" alignItems="flex-start">
        <Container key={result.id} mb="8px" cardStyle={config.cardStyle}>
          <Image draggable={false} loading="lazy" src={src} alt={result.name} bg="#dcdedc" cardStyle={config.cardStyle} />
          {showTypeIcon ? (
            <Box position="absolute" bottom="12px" right="12px">
              <Icon icon="recipe-white" width={24} height={24} />
            </Box>
          ) : null}
        </Container>
        <LineClamp className="primary" lines={2} fontSize="14px" mb="8px" lineHeight="130%" fontWeight="500">
          {result.name}
        </LineClamp>
        {result.totalTime || result.cookingTime ? (
          <Flex alignItems="center">
            <ClockSvg config={config} />
            <Text className="secondary" ml="8px" fontSize="14px">
              {minutesFormat((result.totalTime || 0) + (result.cookingTime || 0))}
            </Text>
          </Flex>
        ) : null}
      </Flex>
    </HoverBox>
  )
}

export default SearchCard
