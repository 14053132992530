import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { colors, formatSeconds, formatStepTime } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Icon from '../ui/Icon.js'
import ColorPicker, { ColorPickerElement, ColorPickerRow } from '../ui/ColorPicker.js'
import VideoUploadModal from '../ui/VideoUploadModal.js'
import VideoPlayer from '../Components/VideoPlayer.js'
import ToggleRedGreen from '../ui/ToggleTwoOptions.js'
import ProcessingPreview from '../Components/ProcessingPreview.js'
import TagsModal from '../ui/TagsModal.js'
import usePrompt from '../ui/BlockerPrompt.js'
import CircleToggle from '../ui/CircleToggle.js'
import { useContent } from '../ContentProvider.js'
import RecipeSearchModal from './RecipeSearchModal.js'
import SearchCard from './SearchCard.js'
import MealPlanCard from './MealPlanCard.js'
import ModalContainer from '../ui/ModalContainer.js'
import PublishModal from '../Recipe/PublishModal.js'
import PreviewModal from '../Recipe/PreviewModal.js'
const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const HoverFlexButton = styled(Flex)`
  button {
    display: none !important;
  }
  &:hover {
    button {
      display: block !important;
    }
  }
`

const MealPlan = (props) => {
  const navigate = useNavigate()
  let { mealPlanId } = useParams()
  const { user } = useAuth()

  const [inputMealPlan, setInputMealPlan] = useState()
  const [videoUploadOpen, setVideoUploadOpen] = useState(false)
  const [publishModalOpen, setPublishModalOpen] = useState(false)
  const [showTagsModal, setShowTagsModal] = useState(false)
  const [coverImageUploadOpen, setCoverImageUploadOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [recipeSearchModalOpen, setRecipeSearchModalOpen] = useState(false)
  const [activeDayIndex, setActiveDayIndex] = useState(0)
  const [stepErrors, setStepErrors] = useState({})
  const [previewModalOpen, setPreviewModalOpen] = useState(false)

  const refresh = async () => {
    const res = await api.get(`/content/get-meal-plan?id=${mealPlanId}`)
    setInputMealPlan(res.data.payload)
    setIsLoading(false)
  }

  useEffect(() => {
    if (mealPlanId) {
      refresh()
    } else {
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        {!isLoading ? (
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={
              inputMealPlan
                ? inputMealPlan
                : {
                    sendTo: 'NONE',
                    published: false,
                    daysArray: [[], [], []]
                  }
            }
            validate={(values) => {
              let errors = {}
              if (!values.name) {
                errors['name'] = 'Please add a name'
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting, resetForm, setFieldValue, setErrors }) => {
              setSubmitting(true)

              try {
                const res = await api.post('/content/meal-plan-update/organisation', values)
                setFieldValue('sendNotification', false)
                setFieldValue('sendNotificationAll', false)
                values.sendNotification = false
                values.sendNotificationAll = false

                if (!values.id || !mealPlanId) {
                  navigate(`/meal-plan/${res.data.payload.id}`)
                  resetForm({ values: { ...values, id: res.data.payload.id } })
                } else {
                  resetForm({ values: values })
                }
                console.log(res)
              } catch (e) {
                console.log(e)
              }
              setSubmitting(false)
            }}
          >
            {({
              values,
              dirty,
              touched,
              resetForm,
              setSubmitting,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              validateForm,
              errors
            }) => {
              usePrompt("Are you sure you want to leave this page? You'll lose your changes", dirty && !isSubmitting)

              const deleteMealPlan = async () => {
                setSubmitting(true)
                const res = await api.post(`/content/meal-plan-delete`, { id: mealPlanId, isDashboard: true })
                navigate('/content')
                setSubmitting(false)
              }

              const publishMealPlan = async () => {
                setPublishModalOpen(false)
                const res = await api.post(`/content/publish-meal-plan`, values)
                resetForm({ values: res.data.payload })
                handleSubmit()
              }

              const unpublishMealPlan = async () => {
                const res = await api.post(`/content/unpublish-meal-plan`, { id: mealPlanId })
                setFieldValue('published', false)
                values.published = false
                setFieldValue('publishedAt', null)
                values.publishedAt = null
                handleSubmit()
              }

              const onDragEnd = (result) => {
                const { source, destination } = result
                const movingRecipe = values.daysArray[Number(source.droppableId)][source.index]
                const newDaysArray = values.daysArray.map((dayArray, i) => {
                  let newArray = dayArray
                  if (destination && Number(source.droppableId) === i) {
                    newArray = dayArray.filter((r, i) => i !== source.index)
                  }
                  if (destination && Number(destination.droppableId) === i) {
                    newArray.splice(destination.index, 0, movingRecipe)
                  }
                  return newArray
                })
                setFieldValue('daysArray', newDaysArray)
              }

              return (
                <>
                  <Flex
                    height="65px"
                    alignItems="center"
                    justifyContent="space-between"
                    backgroundColor="white"
                    borderBottom="2px solid #EEF2F7"
                    px="24px"
                    position="fixed"
                    top="0px"
                    zIndex={100}
                  >
                    <Link to={`/content?showPublished=${values.published}`}>
                      <Flex cursor="pointer" width="auto">
                        <Icon mt="4px" mr="13px" width="16px" height="16px" icon="chevron-left-grey" />
                        <Text lightLight fontSize="14px">
                          Close
                        </Text>
                      </Flex>
                    </Link>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      top="20px"
                      left="calc(50% - 100px)"
                      width="200px"
                    >
                      <Box
                        width="8px"
                        height="8px"
                        mr="8px"
                        borderRadius="8px"
                        backgroundColor={values.published ? 'rgba(102, 203, 159, 1)' : 'rgba(247, 147, 111, 1)'}
                      />
                      <Text light fontWeight="500">
                        {values.published ? (values.publishedAt ? 'Live' : 'Scheduled') : 'Draft'}
                      </Text>
                    </Flex>
                    <Flex width="auto" alignItems="center">
                      {errors && Object.keys(errors).length > 0 ? <Error mr="16px">Please check form errors</Error> : null}
                      {values.id ? (
                        <Flex
                          width="auto"
                          alignItems="center"
                          cursor="pointer"
                          onClick={() => setPreviewModalOpen(true)}
                          mr="24px"
                        >
                          <Icon icon="preview-grey" width="16px" height="16px" />
                          <Text light ml="8px" fontWeight="500">
                            Preview
                          </Text>
                        </Flex>
                      ) : null}
                      <Button
                        isDisabled={!dirty}
                        isLoading={isSubmitting}
                        variant={!values.published ? 'secondary' : 'green'}
                        label="Save"
                        onClick={async () => {
                          const formErrors = await validateForm()
                          if (Object.keys(formErrors).length === 0) {
                            resetForm({ values: values })
                            handleSubmit()
                          } else {
                            // Force all fields to be touched to show errors
                            Object.keys(formErrors).forEach((field) => setFieldTouched(field, true, false))
                          }
                        }}
                      />
                      {values.published && values.publishedAt ? (
                        <a href={`https://${user.subdomain}.theclubb.co/meal-plan/${values.id}`} target="_blank" rel="noreferrer">
                          <Button
                            ml="16px"
                            isLoading={isSubmitting}
                            variant="secondary"
                            renderLeftIcon={() => <Icon icon="open-green" width={12} height={12} />}
                            label="Open"
                          />
                        </a>
                      ) : null}
                      {!values.published ? (
                        <Button
                          ml="16px"
                          isLoading={isSubmitting}
                          variant="green"
                          label="Publish"
                          onClick={async () => {
                            const formErrors = await validateForm()
                            if (Object.keys(formErrors).length === 0) {
                              setPublishModalOpen(true)
                            } else {
                              // Force all fields to be touched to show errors
                              Object.keys(formErrors).forEach((field) => setFieldTouched(field, true, false))
                            }
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                  <Flex height="100%" pt="65px">
                    <Flex
                      flexDirection="column"
                      width="320px"
                      minWidth="320px"
                      height="100%"
                      bg="white"
                      minHeight="calc(100vh - 60px)"
                      position="fixed"
                      left="0px"
                      bottom="0px"
                      zIndex={99}
                    >
                      <Flex
                        px="32px"
                        flexDirection="column"
                        width="auto"
                        pt="40px"
                        height="100%"
                        overflowY="scroll"
                        noScrollBar
                        pb="32px"
                      >
                        <Flex flexDirection="column" pt="65px">
                          <Text fontSize="18px" fontWeight="600" mb="16px">
                            Plan details
                          </Text>
                          {values.published ? (
                            <>
                              {values.updatedAt ? (
                                <Text mb={values.publishedAt ? '16px' : '0px'}>
                                  Last edited: {dayjs(values.updatedAt).format('MMMM D, YYYY h:mm A')}
                                </Text>
                              ) : null}
                              {!values.publishedAt ? (
                                <>
                                  <Text mb="16px" color="#D17757">
                                    Scheduled for {dayjs(values.sendAt).format('MMMM D, YYYY h:mm A')}
                                  </Text>
                                  <Box>
                                    <Button mb="24px" variant="secondary" label="Cancel" onClick={unpublishMealPlan} />
                                  </Box>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          <Field mb="24px" large name="name" label="Name" component={FormInput} dark />
                          <Label>Introduction</Label>
                          <Field
                            mb="32px"
                            large
                            name="introduction"
                            rows="5"
                            label="Introduction"
                            component={FormTextArea}
                            dark
                          />
                          {!values.coverImageUrl ? (
                            <Flex
                              cursor="pointer"
                              onClick={() => setCoverImageUploadOpen(true)}
                              border="1px dashed #CBD5E0"
                              height="190px"
                              minHeight="190px"
                              width="100%"
                              borderRadius="16px"
                              backgroundColor="white"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Text light fontSize="16px" fontWeight="500" mb="16px">
                                Cover Image
                              </Text>
                              <Button variant="secondary" label="Upload" />
                            </Flex>
                          ) : null}
                          {values.coverImageUrl ? (
                            <HoverFlexButton
                              position="relative"
                              cursor="pointer"
                              backgroundColor="white"
                              onClick={() => setCoverImageUploadOpen(true)}
                              height="190px"
                              minHeight="190px"
                              width="100%"
                              borderRadius="16px"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Image
                                src={values.coverImageUrl + '?width=600&height=600'}
                                width="100%"
                                height="100%"
                                objectFit="cover"
                                borderRadius="16px"
                              />
                              <Button
                                zIndex="1"
                                position="absolute"
                                pill
                                bottom="16px"
                                right="16px"
                                variant="grey"
                                label="Change"
                                onClick={() => setCoverImageUploadOpen(true)}
                              />
                            </HoverFlexButton>
                          ) : null}
                          <ImageUploadModal
                            isOpen={coverImageUploadOpen}
                            setIsOpen={setCoverImageUploadOpen}
                            saveFunction={(urls) => {
                              setFieldValue('coverImageUrl', urls[0])
                              setCoverImageUploadOpen(false)
                            }}
                          />
                          <Label mb="8px" mt="32px">
                            Tags
                          </Label>
                          <Flex flexWrap="wrap" noScrollBar>
                            {(values.tags || []).map((tag) => {
                              return (
                                <Flex
                                  alignItems="center"
                                  backgroundColor="rgba(225, 232, 255, 1)"
                                  width="auto"
                                  height="28px"
                                  mr="8px"
                                  mb="8px"
                                  borderRadius="14px"
                                  px="16px"
                                  key={tag}
                                  cursor="pointer"
                                  onClick={() => setShowTagsModal(true)}
                                >
                                  <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                                    {tag}
                                  </Text>
                                </Flex>
                              )
                            })}
                          </Flex>
                          <Text cursor="pointer" light onClick={() => setShowTagsModal(true)} mb="32px">
                            + Add a tag
                          </Text>
                          <TagsModal
                            inputTags={values.tags}
                            saveFunction={(tags) => setFieldValue('tags', tags)}
                            isOpen={showTagsModal}
                            setIsOpen={setShowTagsModal}
                            isRecipes
                          />
                        </Flex>
                        <Flex>
                          {values.id ? (
                            <Flex onClick={deleteMealPlan} alignItems="center" cursor="pointer" mr="24px" width="auto">
                              <Icon icon="bin-dark-grey" width="16px" height="16px" />
                              <Text fontWeight="600" ml="8px" light>
                                Delete
                              </Text>
                            </Flex>
                          ) : null}
                          {values.publishedAt ? (
                            <Button variant="secondary" label="Unpublish" onClick={unpublishMealPlan} />
                          ) : null}
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex pt="40px" flexDirection="column" width="100%" pl="320px">
                      <Flex justifyContent="flex-end" position="fixed" top="76px" right="16px">
                        <Box
                          mr="8px"
                          cursor="pointer"
                          onClick={() => {
                            document.documentElement.scrollLeft -= 240
                          }}
                        >
                          <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.42259 10.7699C6.09715 11.0767 5.56951 11.0767 5.24408 10.7699L0.244078 6.05558C-0.0813599 5.74874 -0.0813599 5.25126 0.244078 4.94442L5.24408 0.230131C5.56951 -0.0767097 6.09715 -0.0767097 6.42259 0.230131C6.74803 0.536971 6.74803 1.03446 6.42259 1.3413L2.01184 5.5L6.42259 9.6587C6.74803 9.96554 6.74803 10.463 6.42259 10.7699Z"
                              fill={'#27272E'}
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M20 5.5C20 5.93394 19.6269 6.28571 19.1667 6.28571L1.66667 6.28571C1.20643 6.28571 0.833334 5.93394 0.833334 5.5C0.833334 5.06606 1.20643 4.71428 1.66667 4.71428L19.1667 4.71429C19.6269 4.71429 20 5.06606 20 5.5Z"
                              fill={'#27272E'}
                            />
                          </svg>
                        </Box>
                        <Box
                          cursor="pointer"
                          onClick={() => {
                            document.documentElement.scrollLeft += 240
                          }}
                        >
                          <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.5774 0.23013C13.9028 -0.0767101 14.4305 -0.0767101 14.7559 0.23013L19.7559 4.94442C20.0814 5.25126 20.0814 5.74874 19.7559 6.05558L14.7559 10.7699C14.4305 11.0767 13.9028 11.0767 13.5774 10.7699C13.252 10.463 13.252 9.96554 13.5774 9.6587L17.9882 5.5L13.5774 1.3413C13.252 1.03446 13.252 0.536971 13.5774 0.23013Z"
                              fill={'#27272E'}
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0 5.5C4.02352e-08 5.06606 0.373096 4.71429 0.833333 4.71429L18.3333 4.71429C18.7936 4.71429 19.1667 5.06606 19.1667 5.5C19.1667 5.93394 18.7936 6.28572 18.3333 6.28572L0.833333 6.28571C0.373096 6.28571 -4.02352e-08 5.93394 0 5.5Z"
                              fill={'#27272E'}
                            />
                          </svg>
                        </Box>
                      </Flex>
                      <Flex width="auto" noScrollBar pl="60px" height="auto">
                        <DragDropContext onDragEnd={onDragEnd}>
                          {values.daysArray.map((dayArray, dayIndex) => {
                            return (
                              <Droppable key={dayIndex} droppableId={`${dayIndex}`}>
                                {(provided, snapshot) => {
                                  return (
                                    <Flex
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                      key={dayIndex}
                                      flexDirection="column"
                                      mr="24px"
                                      width="240px"
                                      minWidth="240px"
                                    >
                                      <Text fontSize="16px" fontWeight="600" mb="16px">
                                        Day {dayIndex + 1}
                                      </Text>
                                      {dayArray.map((recipe, recipeIndex) => {
                                        return (
                                          <Draggable
                                            key={recipe.id + recipe.day + recipe.index}
                                            draggableId={recipe.id + recipe.day + recipe.index}
                                            index={recipeIndex}
                                          >
                                            {(provided, snapshot) => (
                                              <Box
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <MealPlanCard
                                                  deleteFromPlan={() => {
                                                    const newDaysArray = values.daysArray.map((dayArray, i) => {
                                                      if (dayIndex === i) {
                                                        return dayArray.filter((r, i) => i !== recipeIndex)
                                                      } else {
                                                        return dayArray
                                                      }
                                                    })
                                                    setFieldValue('daysArray', newDaysArray)
                                                  }}
                                                  key={recipe.id}
                                                  result={recipe}
                                                />
                                              </Box>
                                            )}
                                          </Draggable>
                                        )
                                      })}
                                      <Flex
                                        border="1px dashed #718096"
                                        borderRadius="16px"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        height="80px"
                                        cursor="pointer"
                                        onClick={() => {
                                          setActiveDayIndex(dayIndex)
                                          setRecipeSearchModalOpen(true)
                                        }}
                                      >
                                        <Icon icon="plus-dark-grey" width={12} height={12} />
                                        <Text light>New recipe</Text>
                                      </Flex>
                                    </Flex>
                                  )
                                }}
                              </Droppable>
                            )
                          })}
                        </DragDropContext>
                        <Flex flexDirection="column" mr="24px" width="240px" minWidth="240px">
                          <Text
                            underline
                            fontSize="16px"
                            fontWeight="600"
                            mb="16px"
                            cursor="pointer"
                            onClick={() => {
                              setFieldValue('daysArray', values.daysArray.concat([[]]))
                            }}
                          >
                            + Add Day
                          </Text>
                        </Flex>
                      </Flex>
                      <RecipeSearchModal
                        save={(recipe) => {
                          const newDaysArray = values.daysArray.map((dayArray, dayIndex) => {
                            if (dayIndex === activeDayIndex) {
                              return dayArray.concat(recipe)
                            } else {
                              return dayArray
                            }
                          })
                          setFieldValue('daysArray', newDaysArray)
                          setRecipeSearchModalOpen(false)
                        }}
                        isOpen={recipeSearchModalOpen}
                        setIsOpen={setRecipeSearchModalOpen}
                      />
                    </Flex>
                  </Flex>
                  <PublishModal
                    isOpen={publishModalOpen}
                    setIsOpen={setPublishModalOpen}
                    setFieldValue={setFieldValue}
                    saveFunction={publishMealPlan}
                    values={values}
                  />
                  <PreviewModal
                    values={values}
                    isOpen={previewModalOpen}
                    onClose={() => setPreviewModalOpen(false)}
                    urlPostFix="meal-plan"
                  />
                </>
              )
            }}
          </Formik>
        ) : (
          <Flex height="500px" justifyContent="center" alignItems="center">
            <BigDarkSpinner />
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default MealPlan
