import React, { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { Formik, Field } from 'formik'
import SelectComponent from '../ui/Select.js'
import { Input, TextArea } from '../ui/Input.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Button from '../ui/Button.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import Icon from '../ui/Icon.js'
import ColorPicker, { ColorPickerElement, ColorPickerRow } from '../ui/ColorPicker.js'
import { BUTTON_FILL, BUTTON_ROUNDING, CARD_OPTIONS, buttonOptions } from './types.js'
import FontPicker from './FontPicker.js'
import { defaultTheme, useTheme } from '../ThemeProvider.js'
import Preview from './Preview.js'
import usePrompt from '../ui/BlockerPrompt.js'
import { ReactSortable } from 'react-sortablejs'
import HomepageBodyContainer from './HomepageSectionsContainer.js'
const FormInput = formikFormWrapper(Input)
const FormTextArea = formikFormWrapper(TextArea)

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`

const DemoText = styled(Text)`
  font-family: ${(props) => props.fontFamily};
`

const Appearance = (props) => {
  let { user, setUser } = useAuth()
  let { refresh: refreshContext } = useTheme()
  const [inputTheme, setTheme] = useState()
  const [logoUploadOpen, setLogoUploadOpen] = useState(false)
  const [imageUploadOpen, setImageUploadOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [primaryFontPickerOpen, setPrimaryFontPickerOpen] = useState(false)
  const [secondaryFontPickerOpen, setSecondaryFontPickerOpen] = useState(false)
  const [isPreviewLoading, setIsPreviewLoading] = useState(false)

  const refresh = async () => {
    const res = await api.get(`/theme/get?organisationId=${user.organisationId}`)
    setTheme(res.data.payload)
    console.log(res)
    setIsLoading(false)
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        {!isLoading ? (
          <Formik
            initialValues={inputTheme ? inputTheme : defaultTheme}
            validate={(values) => {
              const errors = {}
              return errors
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              try {
                const res = await api.post('/theme/update', {
                  ...values,
                  homepageSections: values.homepageSections.map((el) => {
                    return {
                      ...el,
                      id: undefined,
                      selected: undefined,
                      chosen: undefined
                    }
                  })
                })
                await refreshContext()
                setTimeout(() => resetForm({ values: values }), 500)
                console.log(res)
              } catch (e) {
                console.log(e)
              }

              setSubmitting(false)
            }}
          >
            {({ values, dirty, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
              usePrompt("Are you sure you want to leave this page? You'll lose your changes", dirty && !isSubmitting)

              const isDisabled = !dirty
              return (
                <Flex>
                  <Flex
                    px="32px"
                    pl="16px"
                    pt="40px"
                    flexDirection="column"
                    borderRight="1px solid rgba(166, 183, 212, 1)"
                    width="60%"
                    alignItems="center"
                  >
                    <Flex flexDirection="column" maxWidth="640px">
                      <Flex position="relative" flexDirection="column" width="100%">
                        <Flex>
                          <H1 mb="24px" width="100%">
                            Homepage
                          </H1>
                        </Flex>
                      </Flex>

                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <H3 mb="8px">Hero</H3>
                        <Text light mb="16px">
                          A homepage section to explain what you’re offering and why customers should join.
                        </Text>
                        <Field mb="24px" large name="homepageTitle" label="Title" component={FormInput} dark />
                        <Label>Body</Label>
                        <Field mb="24px" large name="homepageBody" component={FormTextArea} dark rows="4" />
                        <Field mb="24px" large name="homepageCta" label="Call to Action" component={FormInput} dark />
                        <ImageUploadModal
                          isOpen={imageUploadOpen}
                          setIsOpen={setImageUploadOpen}
                          saveFunction={(url) => {
                            setFieldValue('homepageImageUrl', url[0])
                            setImageUploadOpen(false)
                          }}
                        />
                        <Flex>
                          <Flex justifyContent="center" flexDirection="column">
                            <Text light fontSize="14px" fontWeight="500">
                              Add an image
                            </Text>
                            <Text mb="16px" fontSize="12px" light>
                              Recommended aspect ratio of 9:16 and minimum width of 1920px
                            </Text>
                            {values.homepageImageUrl ? (
                              <Box>
                                <Button variant="secondary" label="Change image" onClick={() => setImageUploadOpen(true)} />
                              </Box>
                            ) : null}
                          </Flex>
                          {values.homepageImageUrl ? (
                            <Box
                              width="140px"
                              minWidth="140px"
                              height="140px"
                              onClick={() => setImageUploadOpen(true)}
                              cursor="pointer"
                              p="8px"
                            >
                              <Image
                                src={values.homepageImageUrl + '?width=400&height=400'}
                                width="140px"
                                height="140px"
                                minWidth="140px"
                                objectFit="cover"
                                borderRadius="16px"
                              />
                            </Box>
                          ) : (
                            <Flex
                              borderRadius="16px"
                              width="140px"
                              minWidth="140px"
                              height="140px"
                              cursor="pointer"
                              justifyContent="center"
                              alignItems="center"
                              border="2px dashed #A6B7D4"
                              onClick={() => setImageUploadOpen(true)}
                            >
                              <Icon icon="plus-light-grey" width="20px" height="20px" />
                            </Flex>
                          )}
                        </Flex>
                      </Container>
                      <Container p="24px" justifyContent="space-between" mb="32px">
                        <HomepageBodyContainer
                          sections={values.homepageSections}
                          setSections={(sections) => setFieldValue('homepageSections', sections)}
                        />
                      </Container>
                      <Flex justifyContent="flex-end">
                        <Button
                          isLoading={isSubmitting}
                          isDisabled={isDisabled}
                          variant="green"
                          label="Save"
                          onClick={handleSubmit}
                          mb="32px"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex px="16px" flexDirection="column" pt="16px" alignItems="center" width="40%">
                    <Flex flexDirection="column" position="sticky" top="16px" alignItems="center">
                      <Flex justifyContent="flex-end">
                        <Button
                          mr="16px"
                          isDisabled={isDisabled}
                          isLoading={isSubmitting}
                          variant="green"
                          label="Save"
                          onClick={handleSubmit}
                          mb="32px"
                        />
                        <a href={`https://${user.subdomain}.theclubb.co/`} target="_blank" rel="noreferrer">
                          <Button
                            renderLeftIcon={() => <Icon icon="diagonal-arrow-green" width="12px" height="12px" />}
                            variant="secondary"
                            label="Open"
                            mb="32px"
                          />
                        </a>
                      </Flex>
                      <Preview values={values} isLoading={isPreviewLoading} />
                    </Flex>
                  </Flex>
                </Flex>
              )
            }}
          </Formik>
        ) : (
          <Flex height="500px" justifyContent="center" alignItems="center">
            <BigDarkSpinner />
          </Flex>
        )}
      </Flex>
    </>
  )
}

function debounce(func, wait) {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export default Appearance
