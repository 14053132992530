import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { H1, Text } from '../ui/Typography.js'
import Button from '../ui/Button.js'
import SlideUpModal from '../Components/SlideUpModal.js'
import { Flex } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import { useAuth } from '../Auth.js'
import Spinner, { GreenSpinner } from '../ui/Spinner.js'
import { useTheme } from '../ThemeProvider.js'
import dayjs from 'dayjs'
import Image from '../ui/Image.js'

const Styler = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  width: ${({ isDesktop }) => (isDesktop ? '600px' : '370px')};
  overflow-y: scroll;
  border-radius: 16px 16px 0 0;
  border: 1px solid var(--Gray-500, #a0aec0);
  border-bottom: none;

  #title {
    font-family: arial, 'helvetica neue', helvetica, sans-serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
    color: #333333;
  }

  #subtitle {
    font-family: arial, 'helvetica neue', helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 27px;
    color: #696969;
  }

  #author {
    font-family: arial, 'helvetica neue', helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    color: #333333;
    margin-bottom: 0px;
  }

  #date {
    font-family: arial, 'helvetica neue', helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    color: #333333;
  }

  // TODO: Copy from here
  img {
    width: auto;
    max-width: 540px;
    margin-bottom: 16px;
    margin-top: 16px;
    width: fit-content;
  }

  h1,
  h2,
  h3,
  p {
    margin-bottom: 16px;
    font-family: arial, 'helvetica neue', helvetica, sans-serif;
    color: #696969;
  }

  h1 {
    color: #333333;
    font-size: 36px;
    font-weight: 700;
    line-height: 136%;
    margin-top: 16px;
  }

  h2 {
    color: #333333;
    font-size: 28px;
    font-weight: 700;
    line-height: 136%;
    margin-top: 16px;
  }

  h3 {
    color: #333333;
    font-size: 20px;
    font-weight: 700;
    line-height: 136%;
    margin-top: 16px;
  }

  p {
    min-height: 24px;
    color: #696969;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    word-break: break-word;
  }

  a.button {
    color: ${({ theme }) => theme.primaryButtonFontColor};
    font-family: ${({ theme }) => theme.secondaryButtonFontFamily};
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    background-color: ${({ theme }) => theme.primaryButtonColor};
    padding: 14px 20px;
    width: fit-content;
    word-break: break-all;

    ${({ theme }) => {
      console.log({ theme })
      const variant = 'primary'
      if (theme[`${variant}ButtonRound`] === 'PILL') {
        return `border-radius: 50px;`
      }
      if (theme[`${variant}ButtonRound`] === 'ROUNDED') {
        return `border-radius: 8px;`
      }
      if (theme[`${variant}ButtonRound`] === 'SQUARE') {
        return `border-radius: 0px;`
      }
    }}
  }

  .button-table {
    margin-bottom: 32px;
    margin-top: 16px;
  }

  .subscribe-prompt-table {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .subscribe-prompt-description {
    margin-bottom: 32px;
  }

  li {
    margin-bottom: 16px;
    font-family: arial, 'helvetica neue', helvetica, sans-serif;
    color: #696969;
    font-size: 16px;
    font-weight: 400;
  }

  .image-caption {
    font-size: 12px;
    margin-top: -8px;
  }
`

const PreviewModalEmail = ({ values, isDesktop, html, isFree }) => {
  const { user } = useAuth()
  const { theme } = useTheme()

  return (
    <Styler noScrollBar theme={theme} isDesktop={isDesktop}>
      <Flex justifyContent="center" bg={theme.backgroundColor} pt="40px" pb="20px" mb="24px">
        <Image height="60px" src={theme.logo} />
      </Flex>
      <Flex flexDirection="column" px="24px" pb="60px">
        <p id="title">{values.title}</p>
        <Flex borderBottom="1px solid #CCCCCC" mb="24px" />

        <Flex flexDirection="column" dangerouslySetInnerHTML={{ __html: html?.join('') }} />
      </Flex>
    </Styler>
  )
}

export default PreviewModalEmail
