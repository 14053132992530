import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { H1, Text } from '../ui/Typography.js'
import Button from '../ui/Button.js'
import SlideUpModal from '../Components/SlideUpModal.js'
import { Box, Flex } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import { useAuth } from '../Auth.js'
import Spinner, { GreenSpinner } from '../ui/Spinner.js'
import { RemoveScroll } from 'react-remove-scroll'
import Popover from '../Components/ui/Popover.js'
import { Input } from '../ui/Input.js'
import api from '../api.js'
import Toggle from '../ui/Toggle.js'

const StyledIframe = styled.iframe`
  width: 100%;
  border: none;
  overflow: hidden;
  height: ${(props) => (props.fixHeight ? '800px' : 'auto')};
  ${(props) => props.fixHeight && 'min-height: 100%;'}
`

const StyledIframeWrapper = styled(Flex)`
  position: relative;
  border-radius: 16px 16px 0 0;
  border: 1px solid var(--Gray-500, #a0aec0);
  border-bottom: none;
  overflow: scroll;
  ${({ isDesktop }) => !isDesktop && 'width: 390px;'}
`

const SpinnerWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
`

const PreviewModal = ({ isOpen, onClose, values, html, urlPostFix }) => {
  const { user } = useAuth()
  const [isDesktop, setIsDesktop] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isTestPopoverOpen, setIsTestPopoverOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [testEmail, setTestEmail] = useState(user.email)
  const [viewMode, setViewMode] = useState('desktop')
  const [contentType, setContentType] = useState('email')
  const [accessType, setAccessType] = useState('paid')
  const [isSending, setIsSending] = useState(false)
  const [sendStatus, setSendStatus] = useState('')

  const handleIframeLoad = () => {
    setIsLoading(false)
  }

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(`https://${user.subdomain}.theclubb.co/${urlPostFix}/${values.id}?isPreview=true`)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
  }, [user.subdomain, values.id])

  return (
    <RemoveScroll enabled={isOpen}>
      <SlideUpModal isOpen={isOpen} onClose={onClose}>
        <Flex width="auto" p="24px">
          <Flex>
            <Toggle
              width="100px"
              label1="Desktop"
              label2="Mobile"
              value1="desktop"
              value2="mobile"
              selected={viewMode}
              setSelected={setViewMode}
            />

            <Box width="24px" />

            <Toggle
              width="100px"
              label1="Free"
              label2="Paid"
              value1="free"
              value2="paid"
              selected={accessType}
              setSelected={setAccessType}
              mr="40px"
            />
          </Flex>
          <Flex width="auto" mr="40px" position="relative">
            <Popover
              isOpen={isTestPopoverOpen}
              onClose={() => setIsTestPopoverOpen(false)}
              position="left"
              content={
                <Flex flexDirection="column" p="24px" width="450px">
                  <Flex alignItems="flex-end" mb="16px">
                    <Input
                      mr="8px"
                      label="Preview Link"
                      value={`https://${user.subdomain}.theclubb.co/${urlPostFix}/${values.id}?isPreview=true`}
                    />
                    <Button
                      variant="softBlue"
                      label={isCopied ? 'Copied' : 'Copy'}
                      height="37px"
                      borderRadius="8px"
                      renderLeftIcon={() => <Icon icon="copy-blue-grey" width="12px" height="12px" />}
                      onClick={handleCopy}
                    />
                  </Flex>
                  <Text light>Anyone with this link will be able to see the latest version even if unpublished.</Text>
                </Flex>
              }
            >
              <Button
                variant="secondary"
                label="Test"
                renderRightIcon={() => <Icon ml="8px" icon="chevron-down-green" width="14px" height="14px" />}
                onClick={() => setIsTestPopoverOpen(!isTestPopoverOpen)}
              />
            </Popover>
          </Flex>
        </Flex>
        <Flex px="100px" pt="70px" height="calc(100% - 88px)" position="relative" justifyContent="center">
          <StyledIframeWrapper isDesktop={viewMode === 'desktop'} noScrollBar>
            {isLoading && (
              <SpinnerWrapper justifyContent="center" alignItems="center">
                <GreenSpinner size="large" />
              </SpinnerWrapper>
            )}

            <StyledIframe
              fixHeight={urlPostFix === 'recipe' && isDesktop}
              src={
                process.env.NODE_ENV === 'development'
                  ? `http://localhost:3000/${urlPostFix}/${values.id}?isPreview=true${
                      accessType === 'free' ? '&isFreePreview=true' : ''
                    }`
                  : `https://${user.subdomain}.theclubb.co/${urlPostFix}/${values.id}?isPreview=true${
                      accessType === 'free' ? '&isFreePreview=true' : ''
                    }`
              }
              onLoad={handleIframeLoad}
            />
          </StyledIframeWrapper>
        </Flex>
      </SlideUpModal>
    </RemoveScroll>
  )
}

export default PreviewModal
