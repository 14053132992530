import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, numberWithCommas, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, LineClamp } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { useTheme } from '../ThemeProvider.js'
import { useContent } from '../ContentProvider.js'
import Button from '../ui/Button.js'
import { openChat } from '../Components/ChatButton.js'
import { currencySymbols } from '../ui/formatters.js'
import { Line, Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import DatePopover from '../Components/DatePopover.js'
import ErrorBoundary from '../Components/ErrorBoundary.js'
// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, TimeScale)

const FloatingBlueBox = styled(Box)`
  width: 108px;
  height: 121px;
  transform: rotate(19.921deg);
  position: absolute;
  right: -43.765px;
  top: -48px;
  z-index: 1;
  background-color: rgba(91, 179, 249, 1);
  border-radius: 8px;
`

const InnerContainer = styled(Flex)`
  border-radius: 16px;
`

const ImageContainer = styled(Flex)`
  background-color: rgba(192, 192, 192, 0.8);
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  flex-direction: column-reverse;
  padding: 16px;
  position: relative;
  overflow: hidden;
`

const Grid = styled(Flex)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

const TABS = {
  ALL: 'ALL',
  CUMULATIVE_ALL: 'CUMULATIVE_ALL',
  CUMULATIVE_PAID: 'CUMULATIVE_PAID',
  NEW_PAID: 'NEW_PAID'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && `border-bottom: 1px solid ${colors.green};`}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const HomeGraph = ({ hasFirstSubscriber, subscribersByDay, allCustomers, newPaidSubscribers }) => {
  let { user, setUser } = useAuth()

  const { theme } = useTheme()
  const { recipes } = useContent()
  const navigate = useNavigate()

  const currencySymbol = currencySymbols[theme.plans?.[0]?.currency]
  const [activeTab, setActiveTab] = useState(TABS.ALL)
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').toDate())
  const [endDate, setEndDate] = useState(dayjs().toDate())

  const getChartData = () => {
    let sourceData
    let label
    let dataKey
    let chartType

    switch (activeTab) {
      case TABS.ALL:
        sourceData = allCustomers
        label = 'All Subscribers'
        dataKey = 'count'
        chartType = 'bar'
        break
      case TABS.CUMULATIVE_ALL:
        sourceData = allCustomers
        label = 'Cumulative All Subscribers'
        dataKey = 'cumulativeCount'
        chartType = 'line'
        break
      case TABS.CUMULATIVE_PAID:
        sourceData = subscribersByDay
        label = 'Cumulative Paid Subscribers'
        dataKey = 'cumulativeCount'
        chartType = 'line'
        break
      case TABS.NEW_PAID:
        sourceData = subscribersByDay
        label = 'New Paid Subscribers'
        dataKey = 'payingSubscribers'
        chartType = 'bar'
        break
    }

    // Filter the data based on startDate and endDate
    const filteredData = sourceData.filter((item) => {
      const itemDate = new Date(item.day || item.date)
      return (
        itemDate >= dayjs(startDate).subtract(1, 'day').toDate() && itemDate <= dayjs(endDate).add(1, 'day').endOf('day').toDate()
      )
    })

    const datasets = [
      {
        label: label,
        data: filteredData.map((item) => parseInt(item[dataKey])),
        backgroundColor: colors.green,
        borderColor: colors.green,
        borderWidth: 1,
        fill: false,
        tension: 0.1
      }
    ]

    // Add free trials and cancellations datasets only for the NEW_PAID tab
    if (activeTab === TABS.NEW_PAID) {
      datasets.push({
        label: 'Free Trials',
        data: filteredData.map((item) => parseInt(item.freeTrials)),
        backgroundColor: colors.blue,
        borderColor: colors.blue,
        borderWidth: 1,
        fill: false,
        tension: 0.1
      })
    }

    return {
      labels: filteredData.map((item) => {
        const date = new Date(item.day || item.date)
        return date.toISOString().split('T')[0] // Format as YYYY-MM-DD
      }),
      datasets,
      chartType
    }
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM-dd'
          }
        },
        title: {
          display: false
        },
        stacked: activeTab === TABS.NEW_PAID
      },
      y: {
        beginAtZero: true,
        title: {
          display: false
        },
        stacked: activeTab === TABS.NEW_PAID,
        ticks: {
          stepSize: 1,
          precision: 0
        }
      }
    }
  }

  const chartData = getChartData()

  return (
    <Container mb="32px" p="24px">
      <Flex justifyContent="space-between" mb="8px">
        <H3>Subscribers</H3>
        <DatePopover startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
      </Flex>
      <Flex mb="24px">
        <Tab onClick={() => setActiveTab(TABS.ALL)} title="New Subscribers" isActive={activeTab === TABS.ALL} />
        <Tab
          onClick={() => setActiveTab(TABS.CUMULATIVE_ALL)}
          title="Total Subscribers"
          isActive={activeTab === TABS.CUMULATIVE_ALL}
        />
        <Tab onClick={() => setActiveTab(TABS.NEW_PAID)} title="New Paid" isActive={activeTab === TABS.NEW_PAID} />
        <Tab
          onClick={() => setActiveTab(TABS.CUMULATIVE_PAID)}
          title="Total Paid"
          isActive={activeTab === TABS.CUMULATIVE_PAID}
        />
      </Flex>
      {hasFirstSubscriber ? (
        chartData.chartType === 'bar' ? (
          <Bar data={chartData} options={chartOptions} />
        ) : (
          <Line data={chartData} options={chartOptions} />
        )
      ) : (
        <Text fontWeight="500" light textAlign="center" width="100%" py="100px">
          No subscribers yet
        </Text>
      )}
    </Container>
  )
}

// Wrap the HomeGraph component with an ErrorBoundary
const HomeGraphWithErrorBoundary = (props) => {
  return (
    <ErrorBoundary>
      <HomeGraph {...props} />
    </ErrorBoundary>
  )
}

// Export the wrapped component instead
export default HomeGraphWithErrorBoundary
