import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box, DesktopContainer, Flex, MobileContainer } from './Layout.js'
import Icon from './Icon.js'
import Button from './Button.js'

const Container = styled(Flex)`
  position: relative;
  padding-left: ${(props) => props.pl || '0px'};
  overflow-x: scroll;
  scroll-behavior: smooth;
  @media (max-width: 700px) {
    padding-left: ${(props) => props.plm || props.pl};
  }
`

const LeftChevron = styled(Flex)`
  left: 16px;
  height: 40px;
  width: 40px;
  border: 1px solid ${(props) => props.secondaryButtonColor};
  border-radius: 20px;
  z-index: 2;
  width: 40px;
  min-width: 40px;
  height: 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.6;` : '')}
  img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`

const RightChevron = styled(LeftChevron)`
  margin-left: 16px;
  margin-right: 16px;
  img {
    transform: rotate(180deg);
  }
`

const HoverControl = styled(Flex)`
  &&:hover {
    .hover {
      display: flex;
    }
  }
`

export default ({ cards, spacing, slideWidth, pl, plm, config }) => {
  const slider = useRef()
  const [scrollLeft, setScrollLeft] = useState(0)
  const leftChevronDisabled = scrollLeft === 0
  const rightChevronDisabled = scrollLeft >= cards.length * (spacing + slideWidth) - slider.current?.offsetWidth - 100
  const leftChevronOnClick = () => {
    slider.current?.scrollBy(-(slideWidth + spacing) * 2, 0)
  }
  const rightChevronOnClick = () => {
    slider.current?.scrollBy((slideWidth + spacing) * 2, 0)
  }

  useEffect(() => {
    if (slider.current) {
      slider.current.onscroll = () => {
        setScrollLeft(slider?.current?.scrollLeft)
      }
    }
  }, [slider.current])

  return (
    <Flex flexDirection="column" position="relative">
      <Container ref={slider} width="auto" pl={pl} plm={plm} noScrollBar>
        {cards.map((card, i) => {
          return (
            <Box key={card} mr={spacing} minWidth={`${slideWidth}px`} maxWidth={`${slideWidth}px`}>
              {card}
            </Box>
          )
        })}
      </Container>
    </Flex>
  )
}
