import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { motion, AnimatePresence } from 'framer-motion'

import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors } from '../ui/helpers.js'
import { H1, H2, H3, Text, Span, SmallTitle, GradientFont, Error, LineClamp } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api, { AUTH_COOKIE } from '../api.js'
import { openChat } from './ChatButton'
import { useAuth } from '../Auth.js'
import { useModal } from '../Modal.js'
import { isProPlan } from '../Plans.js'
import { useContent } from '../ContentProvider.js'
import { useInbox } from '../InboxProvider.js'
import moment from 'moment'

export const TABS = {
  HOME: 'HOME',
  CONTENT: 'CONTENT',
  CUSTOMERS: 'CUSTOMERS',
  PAYMENTS: 'PAYMENTS',
  PLANS: 'PLANS',
  APPEARANCE: 'APPEARANCE',
  INBOX: 'INBOX',
  SETTINGS: 'SETTINGS',
  ACCOUNT: 'ACCOUNT',
  REFERRALS: 'REFERRALS'
}

export const pathsToTabs = {
  '/': 'HOME',
  '/appearance': 'APPEARANCE',
  '/appearance/theme': 'APPEARANCE',
  '/appearance/homepage': 'APPEARANCE',
  '/content': 'CONTENT',
  '/settings': 'SETTINGS',
  '/customers': 'CUSTOMERS',
  '/analytics': 'ANALYTICS',
  '/inbox': 'INBOX',
  '/account': 'ACCOUNT',
  '/plans': 'PLANS',
  '/payments': 'PAYMENTS',
  '/referrals': 'REFERRALS'
}

export const catchAllRoutes = {
  '/videos/': 'VIDEOS',
  '/campaigns/edit/': 'NO_NAV',
  '/campaigns/': 'CAMPAIGNS'
}

const PopoverContainer = styled(Flex)`
  box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2);
  box-shadow: 0px 1px 2px 2px rgba(50, 50, 71, 0.08);
  border-radius: 32px;
  width: 184px;
  padding-left: 8px;
  padding-right: 8px;
`

const ChangeOrganisation = () => {
  let { user } = useAuth()
  let navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_COOKIE])

  if (!user || !user.organisations || user.organisations.length === 1) {
    return null
  }

  const selectedOrg = user.organisations.find((org) => org.organisationId === user.organisationId)

  const changeOrg = async (newOrganisationId) => {
    const res = await api.post('/organisation/change-organisation', { organisationId: newOrganisationId })
    setCookie(AUTH_COOKIE, res.data.payload)
    localStorage.setItem('organisationChange', Date.now())
    window.location.reload()
  }

  return (
    <Flex mb="16px" flexDirection="column">
      <Flex backgroundColor="#EDF2F7" height="1px" my="16px" width="100%" />
      <PopoverDropdown
        right
        width="184px"
        renderCustomLauncher={() => (
          <PopoverContainer cursor="pointer" py="8px">
            <Flex>
              <Flex
                width="24px"
                height="24px"
                minWidth="24px"
                bg="#5BB3F9"
                borderRadius="24px"
                alignItems="center"
                justifyContent="center"
                mr="8px"
              >
                <Text fontSize="13px" color="white">
                  {selectedOrg.organisationName && selectedOrg.organisationName[0]}
                </Text>
              </Flex>
              <LineClamp fontWeight="500">
                {selectedOrg.organisationName || selectedOrg.firstName + ' ' + selectedOrg.lastName}
              </LineClamp>
            </Flex>
            <Icon ml="8px" mt="7px" icon="select-arrow-grey" width="12px" height="12px" />
          </PopoverContainer>
        )}
        options={user.organisations.map((u) => {
          return { label: u.organisationName || u.firstName + ' ' + u.lastName, onClick: () => changeOrg(u.organisationId) }
        })}
      />
    </Flex>
  )
}

const LinkContainer = styled(Flex)`
  ${(props) =>
    !props.isActive
      ? `&:hover {
    background-color: #edf2f7;
  }`
      : ''}
  padding-left: ${(props) => props.paddingLeft || '16px'};
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
`

const FullWidthLink = styled(Link)`
  width: 100%;
`

const FullWidthA = styled.a`
  width: 100%;
`

const PageLink = ({ isActive, label, icon, to, onClick, href, subPage, isParent, isOpen }) => {
  if (subPage) {
    return (
      <Flex>
        <Box width="auto" borderLeft={`3px solid white`} mr="44px" />
        <FullWidthLink to={to}>
          <LinkContainer isActive={isActive} onClick={onClick} cursor={isActive ? 'default' : 'pointer'} alignItems="center">
            <SmallTitle color={isActive ? 'rgba(0, 125, 91, 1)' : 'rgba(39, 39, 46, 1)'}>{label}</SmallTitle>
          </LinkContainer>
        </FullWidthLink>
      </Flex>
    )
  }
  if (onClick) {
    return (
      <Flex>
        <Box width="auto" borderLeft={`3px solid ${isActive ? 'rgba(0, 125, 91, 1)' : 'white'}`} />
        <LinkContainer isActive={isActive} onClick={onClick} cursor={isActive ? 'default' : 'pointer'} alignItems="center">
          <Icon ml="4px" mr="22px" icon={icon} />
          <Flex>
            <SmallTitle color={isActive ? 'rgba(0, 125, 91, 1)' : 'rgba(39, 39, 46, 1)'}>{label}</SmallTitle>
          </Flex>
          {isParent && (
            <Icon
              transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
              ml="8px"
              mr="12px"
              mt="1px"
              icon="select-arrow-grey"
              width="12px"
              height="12px"
            />
          )}
        </LinkContainer>
      </Flex>
    )
  }
  if (href) {
    return (
      <Flex>
        <Box width="auto" borderLeft={`3px solid ${isActive ? 'rgba(0, 125, 91, 1)' : 'white'}`} />
        <FullWidthA href={href} target="_blank" rel="noreferrer">
          <LinkContainer isActive={isActive} cursor={isActive ? 'default' : 'pointer'} alignItems="center">
            <Icon ml="4px" mr="22px" icon={icon} />
            <SmallTitle color={isActive ? 'rgba(0, 125, 91, 1)' : 'rgba(39, 39, 46, 1)'}>{label}</SmallTitle>
          </LinkContainer>
        </FullWidthA>
      </Flex>
    )
  }
  return (
    <Flex alignItems="center">
      <Box height="24px" width="auto" borderLeft={`3px solid ${isActive ? 'rgba(0, 125, 91, 1)' : 'white'}`} />
      <FullWidthLink to={to}>
        <LinkContainer isActive={isActive} cursor={isActive ? 'default' : 'pointer'} alignItems="center">
          <Icon ml="4px" mr="22px" icon={icon} />
          <SmallTitle color={isActive ? 'rgba(0, 125, 91, 1)' : 'rgba(39, 39, 46, 1)'}>{label}</SmallTitle>
        </LinkContainer>
      </FullWidthLink>
    </Flex>
  )
}

const SideNav = () => {
  let auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['clubb_auth'])
  const { setShowInstallWidgetModal, setShowReferModal } = useModal()
  const { customers, comments } = useInbox()
  const [isCustomersExpanded, setIsCustomersExpanded] = useState(false)
  const [isAppearanceExpanded, setIsAppearanceExpanded] = useState(false)

  const exactMatch = pathsToTabs[location.pathname]
  const catchAllPath =
    !exactMatch &&
    Object.keys(catchAllRoutes).find((route) => {
      return location.pathname.substring(0, route.length) === route
    })
  const activeTab = exactMatch || catchAllRoutes[catchAllPath]

  useEffect(() => {
    if (activeTab !== TABS.APPEARANCE) {
      setIsAppearanceExpanded(false)
    }
    if (activeTab !== TABS.CUSTOMERS && activeTab !== TABS.REFERRALS) {
      setIsCustomersExpanded(false)
    }
  }, [activeTab])

  if (!activeTab || activeTab === 'NO_NAV') {
    return null
  }

  const inboxUnread = auth.user
    ? [...customers, ...comments].filter((content) =>
        moment(auth.user.inboxLastRead).isBefore(content.type === 'COMMENT' ? content.createdAt : content.subscribedAt)
      ).length
    : 0

  return (
    <>
      <Box width="220px" minWidth="220px" mr="24px" />
      <Flex
        flexDirection="column"
        width="220px"
        m="12px"
        px="16px"
        pb="24px"
        minWidth="220px"
        borderRadius="24px"
        height="calc(100vh - 24px)"
        boxShadow="0px 0px 16px 0px rgba(166, 166, 166, 0.15)"
        bg="white"
        position="fixed"
        left="0px"
        top="0px"
        borderRight={`1px solid ${colors.softBlue}`}
        mr="220px"
        overflowY="scroll"
        noScrollBar
      >
        <Flex flexDirection="column" minHeight="425px" height="425px">
          <Image m="24px" mb="16px" src="/logo.svg" width={66} />
          <PageLink
            to="/"
            icon={`home-${activeTab === TABS.HOME ? 'green' : 'grey'}`}
            label="Home"
            isActive={activeTab === TABS.HOME}
          />
          <PageLink
            to="/content"
            icon={`content-${activeTab === TABS.CONTENT ? 'green' : 'grey'}`}
            label="Content"
            isActive={activeTab === TABS.CONTENT}
          />
          <Box position="relative">
            <PageLink
              to="/inbox"
              icon={`inbox-${activeTab === TABS.INBOX ? 'green' : 'grey'}`}
              label="Inbox"
              isActive={activeTab === TABS.INBOX}
            />
            {inboxUnread > 0 && (
              <Flex
                bg="#E1E8FF"
                width="auto"
                height="19px"
                px="10px"
                borderRadius="28px"
                position="absolute"
                top="11px"
                right="24px"
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize="12px" fontWeight="700" color={'#007D5B'}>
                  {inboxUnread}
                </Text>
              </Flex>
            )}
          </Box>
          <PageLink
            isParent
            isOpen={isAppearanceExpanded}
            onClick={() => {
              if (isAppearanceExpanded && activeTab === TABS.APPEARANCE) {
                setIsAppearanceExpanded(true)
              } else {
                setIsAppearanceExpanded(!isAppearanceExpanded)
              }
            }}
            icon={`appearance-${activeTab === TABS.APPEARANCE ? 'green' : 'grey'}`}
            label="Appearance"
          />
          <AnimatePresence>
            {(isAppearanceExpanded || activeTab === TABS.APPEARANCE) && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <PageLink subPage to="/appearance/theme" label="Theme" isActive={location.pathname === '/appearance/theme'} />
                <PageLink
                  subPage
                  to="/appearance/homepage"
                  label="Homepage"
                  isActive={location.pathname === '/appearance/homepage'}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <PageLink
            to="/plans"
            icon={`plans-${activeTab === TABS.PLANS ? 'green' : 'grey'}`}
            label="Plans"
            isActive={activeTab === TABS.PLANS}
          />
          <Box>
            <PageLink
              isParent
              isOpen={isCustomersExpanded}
              onClick={() => {
                if (isCustomersExpanded && (activeTab === TABS.CUSTOMERS || activeTab === TABS.REFERRALS)) {
                  setIsCustomersExpanded(true)
                } else {
                  setIsCustomersExpanded(!isCustomersExpanded)
                }
              }}
              icon={`customers-grey`}
              label="Customers"
            />
            <AnimatePresence>
              {(isCustomersExpanded || activeTab === TABS.CUSTOMERS || activeTab === TABS.REFERRALS) && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <PageLink subPage to="/customers" label="Subscribers" isActive={activeTab === TABS.CUSTOMERS} />
                  <PageLink subPage to="/referrals" label="Referrals" isActive={activeTab === TABS.REFERRALS} />
                </motion.div>
              )}
            </AnimatePresence>
          </Box>
          <PageLink
            to="/settings"
            icon={`settings-${activeTab === TABS.SETTINGS ? 'green' : 'grey'}`}
            label="Settings"
            isActive={activeTab === TABS.SETTINGS}
          />

          <ChangeOrganisation />
        </Flex>

        <Flex height="100%" flexDirection="column" justifyContent="flex-end">
          <PageLink
            icon={`question-mark-grey`}
            label="FAQs"
            href="https://theclubb.notion.site/Clubb-FAQs-and-Guides-6520f4cbde7242ee853bf75410e482ed"
          />
          <PageLink
            icon={`account-${activeTab === TABS.ACCOUNT ? 'green' : 'grey'}`}
            label="Account"
            to="/account"
            isActive={activeTab === TABS.ACCOUNT}
          />
          <PageLink
            icon={`logout-grey`}
            label="Logout"
            onClick={() => {
              removeCookie(AUTH_COOKIE, {
                path: '/'
              })
              localStorage.setItem('logout', Date.now())
              auth.setUser()
              navigate('/login')
            }}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default SideNav
