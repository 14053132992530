import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import EditorLink from '@editorjs/link'
import EditorImage from '@editorjs/image'
import Paragraph from '@editorjs/paragraph'
import DragDrop from 'editorjs-drag-drop'
import AnyButton from 'editorjs-button'
import Underline from '@editorjs/underline'
import api from '../api.js'
import CustomButton from '../Posts/CustomButton.js'
import PatchedList from '../Posts/List.js'

const initEditor = ({ holder, content, onReady, onChange, readOnly, theme, user }) => {
  const tools = {}

  const toolsMap = {
    paragraph: {
      class: Paragraph,
      config: {
        preserveBlank: true
      },
      inlineToolbar: ['link', 'bold', 'italic', 'underline'],
      toolbox: {
        icon: `<img src='/icons/paragraph-grey.svg' />`
      }
    },
    header: {
      class: Header,
      config: {
        levels: [1, 2, 3], // Changed to include level 1
        defaultLevel: 1 // Changed default to 2
      },
      toolbox: [
        {
          icon: `<img src='/icons/h1-grey.svg' />`,
          title: 'Heading 1',
          data: { level: 1 }
        },
        {
          icon: `<img src='/icons/h2-grey.svg' />`,

          title: 'Heading 2',
          data: { level: 2 }
        },
        {
          icon: `<img src='/icons/h3-grey.svg' />`,
          title: 'Heading 3',
          data: { level: 3 }
        }
      ]
    },
    list: {
      class: PatchedList,
      toolbox: [
        {
          icon: `<img src='/icons/bulleted-list-grey.svg' />`,
          title: 'Bulleted List',
          data: { style: 'unordered' }
        },
        {
          icon: `<img src='/icons/numbered-list.svg' />`,
          title: 'Numbered List',
          data: { style: 'ordered' }
        }
      ]
    },
    customButton: {
      class: CustomButton,
      toolbox: {
        icon: `<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path stroke-width="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.10856 3.74136C9.03762 3.71091 8.9542 3.76047 8.9542 3.8393V4.57333C8.9542 4.77536 8.78332 4.93914 8.57252 4.93914H3.76336V7.06086H8.57252C8.78332 7.06086 8.9542 7.22464 8.9542 7.42668V8.1607C8.9542 8.23953 9.03762 8.28909 9.10856 8.25864L12.1494 6.11293C12.1599 6.10555 12.1707 6.09868 12.1819 6.09236C12.2549 6.05116 12.2549 5.94884 12.1819 5.90764C12.1707 5.90132 12.1599 5.89445 12.1494 5.88707L9.10856 3.74136ZM8.19084 3.8393C8.19084 3.18418 8.93084 2.79098 9.50739 3.11662C9.51858 3.12295 9.52943 3.12981 9.5399 3.1372L12.5884 5.28828C13.1372 5.61543 13.1372 6.38456 12.5884 6.71172L9.5399 8.8628C9.52943 8.87019 9.51858 8.87705 9.50739 8.88338C8.93084 9.20902 8.19084 8.81582 8.19084 8.1607V7.79249H3.38168C3.17088 7.79249 3 7.62871 3 7.42668V4.57333C3 4.37129 3.17088 4.20751 3.38168 4.20751H8.19084V3.8393Z" fill="#425466"/>
<rect x="0.5" y="0.5" width="15" height="11" rx="1.5" stroke="#425466"/>
</svg>`
      }
    },
    image: {
      class: EditorImage,
      toolbox: {
        icon: `<img src='/icons/image-tools-grey.svg' />`
      },
      config: {
        types: 'image/jpeg, image/jpg, image/png',
        uploader: {
          uploadByFile: async (file) => {
            console.log({ file })
            let formData = new FormData()
            formData.append('file', file)
            formData.append('name', file.name)
            formData.append('path', 'clubb-posts-images')
            const res = await api.post('/comments/image-upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            const url = res.data.payload.url

            return {
              success: 1,
              file: {
                url
              }
            }
          }
        }
      }
    },
    underline: {
      class: Underline
    }
  }

  Object.keys(toolsMap).forEach((tool) => {
    tools[tool] = toolsMap[tool]
  })

  return new EditorJS({
    readOnly: readOnly,
    holder: holder,
    onReady: onReady,
    data: content || {},
    onChange: onChange,
    tools: tools,
    placeholder: `Write something or type '/' for content types`,
    i18n: {
      messages: {
        tools: {
          AnyButton: {
            Set: 'Add button'
          }
        }
      }
    }
  })
}

export default initEditor
