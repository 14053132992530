import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { LineClamp, Text } from '../ui/Typography'
import { Box, Flex } from '../ui/Layout'
import { minutesFormat } from '../ui/formatters'
import { useAuth } from '../Auth'

const Image = styled.img`
  box-sizing: border-box !important;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  background: -webkit-linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  animation: loading-gradient 4s linear infinite;
  background-size: 200%;
  ${(props) => {
    if (props.cardStyle === 'EXTRA_ROUNDED') return `border-radius: 16px;`
    if (props.cardStyle === 'ROUNDED') return `border-radius: 8px;`
    return `border-radius: 8px;`
  }}
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const Container = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: calc(10 / 7 * 100%);
`

const SearchCard = ({ result }) => {
  const { user, refresh } = useAuth()
  const src = result.coverImageUrl
    ? `${result?.coverImageUrl}?width=700`
    : result?.playbackId
    ? `https://image.mux.com/${result?.playbackId}/thumbnail.jpg?time=0&width=600`
    : undefined

  return (
    <Box position="relative">
      <Flex flexDirection="column" alignItems="flex-start">
        <Container key={result.id} mb="8px" bg="#FDF4E8" borderRadius="8px">
          {src ? <Image draggable={false} loading="lazy" src={src} alt={result.name} bg="#dcdedc" /> : null}
        </Container>
        <LineClamp lines={2} fontSize="14px" mb="8px" lineHeight="130%" fontWeight="500" wordBreak="break-word">
          {result.name}
        </LineClamp>
        <Text light>
          {result.totalTime ? (
            <Flex alignItems="center">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                  fill="#425466"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 3C7.77614 3 8 3.22386 8 3.5V8.70984L11.2481 10.5659C11.4878 10.7029 11.5711 11.0083 11.4341 11.2481C11.2971 11.4878 10.9917 11.5711 10.7519 11.4341L7.25193 9.43412C7.09614 9.3451 7 9.17943 7 9V3.5C7 3.22386 7.22386 3 7.5 3Z"
                  fill="#425466"
                />
              </svg>
              <Text light ml="8px" fontSize="14px">
                {minutesFormat(result.totalTime)}
              </Text>
            </Flex>
          ) : null}
        </Text>
      </Flex>
    </Box>
  )
}

export default SearchCard
