import React, { useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { colors } from '../ui/helpers.js'
import { H3, Text } from '../ui/Typography.js'
import { Flex, Container, Box } from '../ui/Layout.js'
import { useTheme } from '../ThemeProvider.js'
import { currencySymbols } from '../ui/formatters.js'
import { Bar } from 'react-chartjs-2'
import DatePopover from '../Components/DatePopover.js'
import ErrorBoundary from '../Components/ErrorBoundary.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import Button from '../ui/Button.js'
import Icon from '../ui/Icon.js'

export const ViewSelectorPopover = ({ onSelectView, activeView }) => {
  const options = [
    { label: 'Daily', onClick: () => onSelectView('daily') },
    { label: 'Weekly', onClick: () => onSelectView('weekly') },
    { label: 'Monthly', onClick: () => onSelectView('monthly') }
  ]

  const activeLabel = options.find((option) => option.label.toLowerCase() === activeView)?.label || 'Select View'

  return (
    <PopoverDropdown
      width="200px"
      options={options}
      buttonLabel={activeLabel}
      renderCustomLauncher={() => (
        <Button
          pill
          label={activeLabel}
          variant="white"
          shadow
          renderRightIcon={() => <Icon ml="16px" width="16px" height="16px" icon="filter-circle-black" />}
        />
      )}
    />
  )
}

const RevenueGraph = ({ totalRevenue }) => {
  const { theme } = useTheme()
  const currencySymbol = currencySymbols[theme.plans?.[0]?.currency]
  const [startDate, setStartDate] = useState(dayjs().subtract(90, 'day').toDate())
  const [endDate, setEndDate] = useState(dayjs().toDate())
  const [view, setView] = useState('weekly')

  const getChartData = () => {
    // Ensure totalRevenue is an array
    const revenueData = Array.isArray(totalRevenue) ? totalRevenue : []

    // Filter the data based on startDate and endDate
    const filteredData = revenueData.filter((item) => {
      const itemDate = new Date(item.day)
      return itemDate >= dayjs(startDate).startOf('day').toDate() && itemDate <= dayjs(endDate).endOf('day').toDate()
    })

    // Modify the data based on the selected view
    const modifiedData = modifyDataBasedOnView(filteredData, view)

    return {
      labels: modifiedData.map((item) => dayjs(item.day).format('MM-DD')),
      datasets: [
        {
          label: `${view.charAt(0).toUpperCase() + view.slice(1)} Revenue`,
          data: modifiedData.map((item) => parseFloat(item.total)),
          backgroundColor: colors.green,
          borderColor: colors.green,
          borderWidth: 1
        }
      ]
    }
  }

  const modifyDataBasedOnView = (data, view) => {
    if (view === 'daily') {
      return data
    }

    const groupedData = data.reduce((acc, item) => {
      const date = dayjs(item.day)
      let key

      if (view === 'weekly') {
        key = date.startOf('week').format('YYYY-MM-DD')
      } else if (view === 'monthly') {
        key = date.startOf('month').format('YYYY-MM')
      }

      if (!acc[key]) {
        acc[key] = { day: key, total: 0 }
      }

      acc[key].total += parseFloat(item.total)
      return acc
    }, {})

    return Object.values(groupedData)
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${currencySymbol}${context.raw}`
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: false
        },
        ticks: {
          callback: function (value) {
            return `${currencySymbol}${value}`
          }
        }
      }
    }
  }

  const chartData = getChartData()

  return (
    <Container mb="32px" p="24px">
      <Flex justifyContent="space-between" mb="32px">
        <H3>Gross Revenue</H3>
        <Flex width="auto">
          <ViewSelectorPopover onSelectView={setView} activeView={view} />
          <Box width="16px" />
          <DatePopover startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        </Flex>
      </Flex>
      {totalRevenue?.length ? (
        <Bar data={chartData} options={chartOptions} />
      ) : (
        <Text fontWeight="500" light textAlign="center" width="100%" py="100px">
          No revenue yet
        </Text>
      )}
    </Container>
  )
}

// Wrap with ErrorBoundary
const RevenueGraphWithErrorBoundary = (props) => {
  return (
    <ErrorBoundary>
      <RevenueGraph {...props} />
    </ErrorBoundary>
  )
}

export default RevenueGraphWithErrorBoundary
