import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout.js'
import { H3, Text } from '../ui/Typography.js'
import Button from '../ui/Button.js'
import Icon from '../ui/Icon.js'
import { ReactSortable } from 'react-sortablejs'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import { useContent } from '../ContentProvider.js'
import { colors } from '../ui/helpers.js'
import ImageUploadModal from '../ui/ImageUploadModal.js'
import Image from '../ui/Image.js'
import Popover from '../Components/ui/Popover.js'
import { uniq } from 'ramda'
import { toSentenceCase } from '../ui/formatters.js'

export const CONSISTENT_BOTTOM_MARGIN = '100px'

const SectionCard = styled(Flex)`
  position: relative;
  min-height: 104px;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
`

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const TitleInput = styled.input`
  border: none;
  padding: 0;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  background: transparent;

  &:focus {
    outline: none;
  }
`

const BodyInput = styled.textarea`
  border: none;
  padding: 0;
  width: 100%;
  background: transparent;
  resize: none;
  min-height: ${(props) => (props.isBody ? '100px' : 'auto')};
  font-size: 14px;
  color: ${colors.textLight};

  &:focus {
    outline: none;
  }
`

const SECTION_HAS_BODY = {
  paragraph: true,
  latestPosts: false,
  latestRecipe: false,
  carousel: false
}

const SECTION_HAS_BUTTON = {
  paragraph: false,
  'latest-posts': true,
  'latest-recipe': false,
  carousel: true
}

const SECTION_HAS_TITLE = {
  paragraph: true,
  'latest-posts': true,
  'latest-recipe': false,
  carousel: true
}

const SECTION_HAS_IMAGE = {
  paragraph: true,
  'latest-posts': false,
  'latest-recipe': false,
  carousel: false
}

const SECTION_HAS_TAGS = {
  paragraph: false,
  'latest-posts': false,
  'latest-recipe': false,
  carousel: true
}

const SECTION_IS_DISABLED = {
  paragraph: false,
  'latest-posts': false,
  'latest-recipe': true,
  carousel: false
}

const CONTENT_TYPE_OPTIONS = [
  { label: 'Recipes', value: 'recipes' },
  { label: 'Meal Plans', value: 'mealPlans' }
]

const HomepageBodyContainer = ({ sections, setSections }) => {
  const { recipes, mealPlans, posts } = useContent()
  const [openImageModalIndex, setOpenImageModalIndex] = useState(null)
  const [filterPopoverOpen, setFilterPopoverOpen] = useState({})

  const inputAllTags = uniq(
    [...recipes, ...mealPlans]
      .map((el) => el.tags)
      .flat()
      .filter((el) => el)
      .sort()
  )

  const latestRecipe = recipes && recipes.filter((el) => el.publishedAt)[0]
  const latestRecipeCoverImage = latestRecipe?.coverImageUrl
    ? `${latestRecipe?.coverImageUrl}?width=600`
    : latestRecipe?.playbackId
    ? `https://image.mux.com/${latestRecipe?.playbackId}/thumbnail.jpg?time=0&width=600`
    : undefined

  const addOptions = [
    {
      label: 'Carousel',
      onClick: () => {
        setSections([
          {
            title: 'Carousel Title',
            type: 'carousel',
            buttonLabel: 'View all'
          },
          ...sections
        ])
      }
    },
    {
      label: 'Paragraph',
      onClick: () => {
        setSections([
          {
            title: 'Title',
            body: 'Body',
            type: 'paragraph'
          },
          ...sections
        ])
      }
    },
    {
      label: 'Latest Posts',
      onClick: () => {
        setSections([
          {
            title: 'Latest Posts',
            type: 'latest-posts',
            buttonLabel: 'View all'
          },
          ...sections
        ])
      }
    },
    {
      label: 'Latest Recipe',
      onClick: () => {
        setSections([
          {
            title: 'Latest Recipe',
            type: 'latest-recipe',
            buttonLabel: 'Cook now'
          },
          ...sections
        ])
      }
    }
  ]

  const handleTagSelect = (sectionIndex, tag) => {
    const section = sections[sectionIndex]
    const newTag = section.tag === tag ? null : tag

    const newSections = sections.map((s, i) => (i === sectionIndex ? { ...s, tag: newTag } : s))
    setSections(newSections)
    setFilterPopoverOpen((prev) => ({ ...prev, [sectionIndex]: false }))
  }

  return (
    <Flex flexDirection="column" bg="white" borderRadius="8px">
      <Flex alignItems="center">
        <Flex flexDirection="column">
          <H3 mb="8px">Body</H3>
          <Text light mb="16px">
            Add and rearrange other homepage sections.
          </Text>
        </Flex>
        <PopoverDropdown
          width="200px"
          buttonLabel="+ Add"
          options={addOptions}
          renderCustomLauncher={() => <Button variant="green" label="+ Add" />}
        />
      </Flex>

      <ReactSortable list={sections || []} setList={setSections} animation={200} handle=".handle">
        {sections?.map((section, i) => (
          <SectionCard key={i} backgroundColor={SECTION_IS_DISABLED[section.type] ? '#F7FAFC' : 'white'}>
            <Flex width="100%" alignItems="center">
              <GrabIcon className="handle" mr="16px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
              <Flex flexDirection="column" height="100%">
                <Flex>
                  <Flex justifyContent="space-between" width="100%" mb="8px">
                    {SECTION_HAS_TITLE[section.type] && (
                      <TitleInput
                        value={section.title}
                        onChange={(e) => {
                          const newSections = sections.map((s, index) => (index === i ? { ...s, title: e.target.value } : s))
                          setSections(newSections)
                        }}
                      />
                    )}
                    {section.type === 'latest-recipe' && (
                      <Text fontSize="16px" fontWeight="600" cursor="default">
                        {latestRecipe?.name || 'Latest Recipe'}
                      </Text>
                    )}
                    <Text lightLight fontSize="12px" whiteSpace="nowrap" cursor="default" ml="8px">
                      {toSentenceCase(section.type.replace(/-/g, ' '))}
                    </Text>
                  </Flex>
                </Flex>
                {SECTION_HAS_BODY[section.type] && (
                  <Flex>
                    <BodyInput
                      value={section.body}
                      isBody={true}
                      onChange={(e) => {
                        const newSections = sections.map((s, index) => (index === i ? { ...s, body: e.target.value } : s))
                        setSections(newSections)
                      }}
                    />
                  </Flex>
                )}

                {SECTION_HAS_BUTTON[section.type] && (
                  <Flex>
                    <BodyInput
                      isBody={false}
                      value={section.buttonLabel}
                      onChange={(e) => {
                        const newSections = sections.map((s, index) => (index === i ? { ...s, buttonLabel: e.target.value } : s))
                        setSections(newSections)
                      }}
                    />
                  </Flex>
                )}

                {SECTION_HAS_IMAGE[section.type] &&
                  (section.imageUrl ? (
                    <Box onClick={() => setOpenImageModalIndex(i)} width="100px" height="100px" mt="16px">
                      <Image
                        cursor="pointer"
                        borderRadius="16px"
                        src={section.imageUrl + '?width=300'}
                        width="100%"
                        height="100%"
                        objectFit="cover"
                      />
                    </Box>
                  ) : (
                    <Flex p="8px" cursor="pointer" onClick={() => setOpenImageModalIndex(i)}>
                      <Icon cursor icon="image-grey2" width="16px" height="16px" />
                    </Flex>
                  ))}
                <ImageUploadModal
                  isOpen={openImageModalIndex === i}
                  setIsOpen={(isOpen) => setOpenImageModalIndex(isOpen ? i : null)}
                  saveFunction={(url) => {
                    const newSections = sections.map((s, index) => (index === i ? { ...s, imageUrl: url[0] } : s))
                    setSections(newSections)
                  }}
                />

                {SECTION_HAS_TAGS[section.type] && (
                  <Flex width="auto" gap="8px" alignItems="center">
                    <Popover
                      isOpen={filterPopoverOpen[`${i}-content`]}
                      onClose={() => setFilterPopoverOpen((prev) => ({ ...prev, [`${i}-content`]: false }))}
                      position="right"
                      content={
                        <Box width="200px" p="16px">
                          <Flex
                            alignItems="center"
                            p="8px"
                            cursor="pointer"
                            justifyContent="space-between"
                            onClick={() => {
                              const newSections = sections.map((s, index) => (index === i ? { ...s, contentType: null } : s))
                              setSections(newSections)
                              setFilterPopoverOpen((prev) => ({ ...prev, [`${i}-content`]: false }))
                            }}
                          >
                            <Text fontWeight="400" fontSize="14px">
                              All
                            </Text>
                            {!section.contentType && <Icon icon="tick-green-2" width={16} height={16} />}
                          </Flex>
                          {CONTENT_TYPE_OPTIONS.map((option) => (
                            <Flex
                              key={option.value}
                              alignItems="center"
                              p="8px"
                              cursor="pointer"
                              justifyContent="space-between"
                              onClick={() => {
                                const newSections = sections.map((s, index) =>
                                  index === i
                                    ? { ...s, contentType: section.contentType === option.value ? null : option.value }
                                    : s
                                )
                                setSections(newSections)
                                setFilterPopoverOpen((prev) => ({ ...prev, [`${i}-content`]: false }))
                              }}
                            >
                              <Text fontWeight="400" fontSize="14px">
                                {option.label}
                              </Text>
                              {section.contentType === option.value && <Icon icon="tick-green-2" width={16} height={16} />}
                            </Flex>
                          ))}
                        </Box>
                      }
                    >
                      <Box width="fit-content">
                        <Button
                          variant="softBlue"
                          minWidth="auto"
                          height="28px"
                          fontSize="12px"
                          fontWeight="500"
                          backgroundColor="#E3ECF7"
                          paddingLeft="16px"
                          paddingRight="16px"
                          color="#27272E"
                          label={!section.contentType ? 'All' : section.contentType === 'recipes' ? 'Recipes' : 'Meal Plans'}
                          onClick={() => setFilterPopoverOpen((prev) => ({ ...prev, [`${i}-content`]: !prev[`${i}-content`] }))}
                        />
                      </Box>
                    </Popover>
                    <Box width="4px" />
                    <Popover
                      isOpen={filterPopoverOpen[i]}
                      onClose={() => setFilterPopoverOpen((prev) => ({ ...prev, [i]: false }))}
                      position="right"
                      content={
                        <Box maxHeight="300px" overflowY="auto" width="200px" p="16px" noScrollBar>
                          <Flex
                            alignItems="center"
                            p="8px"
                            cursor="pointer"
                            justifyContent="space-between"
                            onClick={() => handleTagSelect(i, null)}
                          >
                            <Text fontWeight="400" fontSize="14px">
                              Recent
                            </Text>
                            {!section.tag && <Icon icon="tick-green-2" width={16} height={16} />}
                          </Flex>
                          {inputAllTags
                            .sort((a, b) => a.localeCompare(b))
                            .map((tag) => (
                              <Flex
                                key={tag}
                                alignItems="center"
                                p="8px"
                                cursor="pointer"
                                justifyContent="space-between"
                                onClick={() => handleTagSelect(i, tag)}
                              >
                                <Text fontWeight="400" fontSize="14px">
                                  {tag}
                                </Text>
                                {section.tag === tag && <Icon icon="tick-green-2" width={16} height={16} />}
                              </Flex>
                            ))}
                        </Box>
                      }
                    >
                      <Box width="fit-content">
                        <Button
                          variant="softBlue"
                          minWidth="auto"
                          height="28px"
                          fontSize="12px"
                          fontWeight="500"
                          backgroundColor="#E3ECF7"
                          color="#27272E"
                          paddingLeft="16px"
                          paddingRight="16px"
                          label={!section.tag ? 'Recent' : section.tag}
                          onClick={() => setFilterPopoverOpen((prev) => ({ ...prev, [i]: !prev[i] }))}
                        />
                      </Box>
                    </Popover>
                  </Flex>
                )}

                <Box position="absolute" right="16px" bottom="16px">
                  <Icon
                    onClick={() => {
                      const newSections = sections.filter((_, index) => index !== i)
                      setSections(newSections)
                    }}
                    icon="bin-grey"
                    width="16px"
                    height="16px"
                    cursor="pointer"
                  />
                </Box>
              </Flex>
            </Flex>
          </SectionCard>
        ))}
      </ReactSortable>
    </Flex>
  )
}

export default HomepageBodyContainer
