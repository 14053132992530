import { useEffect, useState } from 'react'
import styled from 'styled-components'
import api from '../api'
import HomepageCarousel from '../ui/HomepageCarousel'
import { H3, Text } from '../ui/Typography'
import { Flex } from '../ui/Layout'
import { CONSISTENT_BOTTOM_MARGIN } from './HomepageSectionsContainer'
import MealPlanSearchCard from '../ui/MealPlanSearchCard'
import SearchCard from '../ui/SearchCard'

const ShimmerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: calc(10 / 7 * 100%);

  background: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`

const LoadingCard = () => {
  return <ShimmerWrapper />
}

const LatestCarousel = ({ latest, contentType, tag, title, buttonLabel, config }) => {
  const [contentToShow, setContentToShow] = useState(
    contentType || tag
      ? [{ type: 'LOADING' }, { type: 'LOADING' }, { type: 'LOADING' }, { type: 'LOADING' }, { type: 'LOADING' }]
      : latest
  )

  const searchableContent = contentType === 'recipes' ? 'RECIPES' : contentType === 'mealPlans' ? 'MEAL_PLANS' : 'BOTH'
  useEffect(() => {
    if (!contentType && !tag) {
      setContentToShow(latest)
      return
    }

    const asyncFunc = async () => {
      const res = await api.post('/search/search', {
        organisationId: config.organisationId,
        page: 1,
        searchableContent,
        tags: tag ? [tag] : [],
        query: ''
      })
      setContentToShow(res.data.payload.results)
    }
    asyncFunc()
  }, [latest, contentType, tag])

  if (contentToShow.length === 0) return null
  return (
    <Flex flexDirection="column" mb={CONSISTENT_BOTTOM_MARGIN}>
      <H3 className="primary" fontSize="24px" fontWeight="700" lineHeight="136%" mb="24px" px="16px">
        {title}
      </H3>
      <HomepageCarousel
        config={config}
        slideWidth={280}
        spacing={16}
        cards={contentToShow?.map((result) => {
          if (result.type === 'LOADING') {
            return <LoadingCard key={result.id} />
          }
          if (result.type === 'MEAL_PLAN') {
            return <MealPlanSearchCard key={result.id} result={result} showTypeIcon config={config} />
          }
          return <SearchCard key={result.id} result={result} showTypeIcon config={config} />
        })}
        pl="16px"
      />
      <Flex alignItems="center" width="auto" pl="16px" mt="16px">
        <Text className="secondary" fontSize="16px" fontWeight="600" light mr="8px">
          {buttonLabel}
        </Text>
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.64645 1.97982C4.84171 1.78456 5.15829 1.78456 5.35355 1.97982L11.3536 7.97982C11.5488 8.17508 11.5488 8.49167 11.3536 8.68693L5.35355 14.6869C5.15829 14.8822 4.84171 14.8822 4.64645 14.6869C4.45118 14.4917 4.45118 14.1751 4.64645 13.9798L10.2929 8.33337L4.64645 2.68693C4.45118 2.49167 4.45118 2.17508 4.64645 1.97982Z"
            fill={config.secondaryFontColor}
          />
        </svg>
      </Flex>
    </Flex>
  )
}

export default LatestCarousel
