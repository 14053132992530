export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const stringToUrl = (x) => {
  return x.replace(/ /g, '-').toLowerCase()
}

export const toTitleCase = (phrase) => {
  if (phrase.length === 3) {
    return phrase.toUpperCase()
  }
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const urlToString = (x) => {
  return toTitleCase(x.replace(/-/g, ' '))
}

export function msToTime(duration) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  return (hours > 0 ? hours + 'h ' : '') + minutes + 'm'
}

export function minutesFormat(duration) {
  var minutes = Math.floor(duration % 60),
    hours = Math.floor((duration / 60) % 24)

  if (minutes === 0) {
    return hours > 0 ? hours + ` hour${hours !== 1 ? 's' : ''}` : ''
  }
  if (minutes > 0 && hours === 0) {
    return minutes > 0 ? minutes + ` minute${minutes !== 1 ? 's' : ''}` : ''
  }
  return (hours > 0 ? hours + ' hrs ' : '') + (minutes > 0 ? minutes + ` min${minutes !== 1 ? 's' : ''}` : '')
}

export const currencySymbols = {
  USD: '$', // United States Dollar
  EUR: '€', // Euro
  JPY: '¥', // Japanese Yen
  GBP: '£', // British Pound Sterling
  AUD: 'A$', // Australian Dollar
  CAD: 'C$', // Canadian Dollar
  CHF: 'CHF', // Swiss Franc
  CNY: '¥', // Chinese Yuan
  SEK: 'kr', // Swedish Krona
  NZD: 'NZ$' // New Zealand Dollar
}

export const toSentenceCase = (sentence) => {
  if (!sentence) return ''
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase()
}
