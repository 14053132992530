import styled from 'styled-components'
import { CONSISTENT_BOTTOM_MARGIN } from './HomepageSectionsContainer'
import { minutesFormat } from '../ui/formatters'
import { useContent } from '../ContentProvider'
import { Box, Flex } from '../ui/Layout'
import Image from '../ui/Image'
import { Text } from '../ui/Typography'
import Button from '../ui/Button'

const RecipeContainer = styled(Flex)`
  flex-direction: column-reverse;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
`

const RecipeTextContainer = styled(Flex)`
  margin-bottom: 32px;
  min-width: unset;
  width: 100%;
  max-width: 600px;
`

const RecipeImageContainer = styled(Box)`
  max-width: 600px;
  width: 100%;
  min-width: unset;
`

export const ClockSvg = ({ config }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
        fill={config.secondaryFontColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 3C7.77614 3 8 3.22386 8 3.5V8.70984L11.2481 10.5659C11.4878 10.7029 11.5711 11.0083 11.4341 11.2481C11.2971 11.4878 10.9917 11.5711 10.7519 11.4341L7.25193 9.43412C7.09614 9.3451 7 9.17943 7 9V3.5C7 3.22386 7.22386 3 7.5 3Z"
        fill={config.secondaryFontColor}
      />
    </svg>
  )
}

export default function LatestRecipe({ title, buttonLabel, config }) {
  const { recipes } = useContent()
  const recipe = recipes.filter((el) => el.publishedAt).length > 0 ? recipes.filter((el) => el.publishedAt)[0] : undefined

  if (!recipe) return null

  const coverImage = recipe.coverImageUrl
    ? `${recipe.coverImageUrl}?width=600`
    : recipe.playbackId
    ? `https://image.mux.com/${recipe.playbackId}/thumbnail.jpg?time=0&width=600`
    : undefined

  return (
    <Flex justifyContent="center" mb={CONSISTENT_BOTTOM_MARGIN}>
      <Flex flexDirection="column" alignItems="center" maxWidth="1600px">
        <RecipeContainer>
          <RecipeImageContainer mr="0px">
            <Image src={coverImage} alt="homepage-image" width="100%" height="100%" objectFit="cover" borderRadius="16px" />
          </RecipeImageContainer>
          <RecipeTextContainer>
            <Flex flexDirection="column">
              <Text className="secondary" fontSize="18px" mb="24px">
                {title || 'Latest Recipe'}
              </Text>
              <Text className="primary" fontWeight="900" fontSize="40px" mb="8px" lineHeight="normal" wordBreak="break-word">
                {recipe.name}
              </Text>
              <Text
                className="secondary"
                fontWeight="400"
                light
                fontSize="18px"
                lineHeight="normal"
                mb="32px"
                wordBreak="break-word"
              >
                {recipe.intro}
              </Text>
              <Flex>
                <Button className="primary" label={'Cook now'} />
                {recipe.totalTime || recipe.cookingTime ? (
                  <Flex ml="16px" alignItems="center">
                    <ClockSvg config={config} />
                    <Text ml="8px" fontSize="16px" light>
                      {minutesFormat((recipe.totalTime || 0) + (recipe.cookingTime || 0))}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>
            </Flex>
          </RecipeTextContainer>
        </RecipeContainer>
      </Flex>
    </Flex>
  )
}
