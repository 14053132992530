'use client'

import styled from 'styled-components'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import hexToRgba from 'hex-to-rgba'
import { Flex } from '../ui/Layout'
import { CONSISTENT_BOTTOM_MARGIN } from './HomepageSectionsContainer'
import { H3, LineClamp, Text } from '../ui/Typography'
import Image from '../ui/Image'
import { FontText } from './Preview'

const DesktopHeroPostContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  @media (max-width: 1000px) {
    display: none;
  }
`

const MobileHeroPostContainer = styled(Flex)`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`

const InlineImageContainer = styled(Flex)`
  width: 160px;
  min-width: 160px;
  height: 120px;
  @media (max-width: 700px) {
    width: 98px;
    min-width: 98px;
    height: 74px;
  }
`

const FullWidthLink = styled.a`
  width: 100%;
`

const AspectRatioContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(props) => props.ratio || '75%'}; // Default to 4:3 aspect ratio
`

const AspectRatioContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default function LatestPosts({ posts, title, buttonLabel, config }) {
  const heroPost = posts[0]
  const isOnlyOnePost = posts.length === 1

  return (
    <Flex justifyContent="center" flexDirection="column" mb={CONSISTENT_BOTTOM_MARGIN}>
      <H3 fontSize="24px" fontWeight="700" lineHeight="136%" mb="24px" ml="16px">
        {title || 'Latest posts'}
      </H3>
      {heroPost.thumbnailImageUrl ? (
        <Flex mb="16px">
          <AspectRatioContainer ratio="60%">
            <AspectRatioContent>
              <Image
                objectFit="cover"
                width="100%"
                height="100%"
                src={heroPost.thumbnailImageUrl + '?width=1000'}
                alt={heroPost.title}
              />
            </AspectRatioContent>
          </AspectRatioContainer>
        </Flex>
      ) : null}

      <Flex flexDirection="column" px="16px">
        <Text className="secondary" config={config} mb="16px">
          Latest read
        </Text>
        <LineClamp className="primary" lines={4} fontSize="36px" lineHeight="normal" fontWeight="800" mb="16px">
          {heroPost.title}
        </LineClamp>
        <LineClamp className="secondary" lines={3} fontSize="16px" lineHeight="160%" light fontWeight="400" mb="16px">
          {heroPost.subtitle}
        </LineClamp>
        <Text className="secondary" light fontWeight="600" fontSize="14px" lineHeight="130%" mb="32px">
          {dayjs(heroPost.publishedAt).format('MMMM D, YYYY')}
        </Text>
        <Flex backgroundColor={hexToRgba(config.primaryFontColor, 0.1)} height="1px" width="80%" mx="10%" mb="32px" />
        {posts.slice(1).map((post, index) => {
          return (
            <>
              <Flex alignItems="center">
                <Flex flexDirection="column">
                  <LineClamp className="primary" lines={1} fontSize="18px" lineHeight="normal" fontWeight="700" mb="16px">
                    {post.title}
                  </LineClamp>
                  <LineClamp className="secondary" lines={2} fontSize="16px" lineHeight="136%" light fontWeight="400" mb="16px">
                    {post.subtitle}
                  </LineClamp>
                  <Text className="secondary" light lineHeight="100%">
                    {dayjs(post.publishedAt).format('MMMM D, YYYY')}
                  </Text>
                </Flex>

                {post.thumbnailImageUrl ? (
                  <InlineImageContainer ml="16px">
                    <Image
                      objectFit="cover"
                      width="100%"
                      height="100%"
                      borderRadius="16px"
                      src={post.thumbnailImageUrl + '?width=400'}
                      alt={post.title}
                    />
                  </InlineImageContainer>
                ) : null}
              </Flex>
              <Flex
                backgroundColor={hexToRgba(config.primaryFontColor, 0.1)}
                height="1px"
                width="100%"
                my="24px"
                mb={index === posts.slice(1).length - 1 ? '0px' : '24px'}
              />
            </>
          )
        })}
      </Flex>
    </Flex>
  )
}
