import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import { H3, Text } from '../ui/Typography'
import Image from '../ui/Image'
import { CONSISTENT_BOTTOM_MARGIN } from './HomepageSectionsContainer'

const RecipeContainer = styled(Flex)`
  flex-direction: column-reverse;
  padding-left: 0px;
  padding-right: 0px;
  align-items: center;
`

const RecipeTextContainer = styled(Flex)`
  margin-bottom: ${(props) => (props.hasImage ? '32px' : '0px')};
  min-width: unset;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 600px;
`

const RecipeImageContainer = styled(Box)`
  overflow: hidden;
  margin-bottom: 32px;
  max-width: 600px;
  width: 100%;
  min-width: unset;
  border-radius: 0px;
`

export default function ParagraphSection({ title, body, imageUrl }) {
  return (
    <RecipeContainer mb={CONSISTENT_BOTTOM_MARGIN}>
      <RecipeTextContainer hasImage={!!imageUrl}>
        <Flex flexDirection="column">
          <H3 fontSize="32px" fontWeight="700" lineHeight="136%" mb="24px">
            {title}
          </H3>
          <Text className="secondary" fontSize="16px" lineHeight="130%" light whiteSpace="pre-line">
            {body}
          </Text>
        </Flex>
      </RecipeTextContainer>
      {imageUrl && (
        <RecipeImageContainer>
          <Image src={imageUrl} alt={title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </RecipeImageContainer>
      )}
    </RecipeContainer>
  )
}
